<div *ngIf="{ class: classDetail, program: program } as detailData">
    <div class-roster-header>
        <ng-container *ngIf="program; else displayProgramErrorMessage">
            <div breadcrumb>
                <span>
                    <img src="assets/icons/programs-drawer-title.svg" alt="">
                </span>
                <p>{{detailData.program?.name}} • {{detailData.program?.startDate | dateFormat :'mm/dd/yy'}} -
                    {{detailData.program?.endDate
                    | dateFormat :'mm/dd/yy'}}</p>
            </div>
        </ng-container>
        <ng-template #displayProgramErrorMessage>
            <p class="background-white m-t-xs">
                <span class="m-l-lg">
                    <error-message [errorMessage]="programErrorMessage"></error-message>
                </span>
            </p>
        </ng-template>

        <ng-container *ngIf="classDetail?.length; else displayclassDetailErrorMessage">
            <div title>
                <h1>{{selectedClassDetails?.title}}</h1>
                <span
                    class="{{selectedClassDetails?.startDate |classcolor : selectedClassDetails?.endDate }}">{{selectedClassDetails?.startDate
                    | classstatus : selectedClassDetails?.endDate}}</span>
            </div>
            <div items>
                <div content>
                    <p>CLASS ID
                        <span dblock>{{displayClassId}}</span>
                    </p>
                </div>
                <div content>
                    <p>COURSE NUMBER
                        <span dblock>{{displayCourseId}}</span>
                    </p>
                </div>
                <div content>
                    <p>CHECK-IN CODE
                        <span dblock>{{selectedClassDetails?.checkInCode || 'N/A'}}</span>
                    </p>
                </div>
                <div date (click)="toggleDropdown()">
                    <div>
                        <span>
                            <img src="assets/icons/date-table.svg" alt="">
                        </span>
                        <div>
                            <span>{{selectedClassDetails?.startDate | dateFormat : 'mmddyyyy'}}</span>
                            <span>{{selectedClassDetails?.startDate | dateFormat :'fulldayname'}}</span>
                        </div>
                        <div class="date-dropdown" [attr.show]="isDatePicker">
                            <div dd-item *ngFor="let item of detailData.class" (click)="selectDate($event, item)"
                                [ngClass]="{'disable':selectedClassDetails?.startDate === item.StartDate, 'active':selectedClassDetails?.startDate >= item.StartDate}">
                                {{item?.startDate | dateFormat : 'mmddyyyy'}}
                                <span>{{item?.startDate | dateFormat :'fulldayname'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div time>
                    <div>
                        <span>
                            <img src="assets/icons/time-table.svg" alt="">
                        </span>
                        <div>
                            <span>{{selectedClassDetails?.startDate |dateFormat : 'coursetime'}} -</span>
                            <span>{{selectedClassDetails?.endDate |dateFormat : 'coursetime'}}</span>
                        </div>
                    </div>
                </div>
                <div place>
                    <span>
                        <i>
                            <img src="assets/icons/location-table.svg" alt="">
                        </i>
                        {{selectedClassDetails?.location || 'TBD'}}</span>
                </div>
                <div content class="check-in-users">
                    <p>CHECKED-IN USERS</p>
                    <span>{{selectedClassDetails?.checkedInUsersCount}}</span>
                </div>
            </div>
        </ng-container>

        <ng-template #displayclassDetailErrorMessage>
            <p class="background-white m-t-xs">
                <span class="m-l-lg">
                    <error-message [errorMessage]="classDetailErrorMessage"></error-message>
                </span>
            </p>
        </ng-template>

    </div>
    <div cr-popup-body>
        <class-roster [programId]="programId" [classId]="classId" [reportName]="detailData.program?.sabaProgramId + '_' + displayClassId"
            [classDate]="classDate" [classEndDate]="classEndDate" (editedIndexEvent)="selectedEvent($event)" 
            (isAnyFormOpen)="anyTimeInputFormOpen($event)" 
            [facilitatorAttendance]="facilitatorAttendance"></class-roster>
    </div>
</div>