<div panel-list *ngIf="sessions;else loading">
    <div>
        <div class-roster-body>
            <div class-roster-title>
                <h1>All Sessions</h1>
                <div (click)="downloadReport()" class="export-button">Export to Excel
                    <img src="assets/icons/excel.svg" alt="" ml10>
                </div>
            </div>
        </div>
    </div>
    <div Class-list>
        <div head [attr.mode]="searchmode">
            <div left>
            </div>
            <div right>
                <span><i class="material-icons">settings</i></span>
                <span>Event Setting Mode</span>
                <span [ngClass]="{'disable':!isDisplay}" (click)="togglesession()"><i
                        class="material-icons">clear</i></span>
                <span [ngClass]="{'disable':isDisplay}">
                    <button (click)="cancel()">Cancel</button>
                    <button (click)="save()">Save</button>
                </span>
                <div close class="undo">
                    <span save *ngIf="isSaved">Saved successfully</span>
                    <span *ngIf="isSaved">
                        <i class="material-icons"> check_circle_outline</i>
                    </span>
                </div>
            </div>
            <span><i class="material-icons">local_library</i></span>
            <div>Sessions ( Active - {{getSessionCount()}} )</div>
            <div right-head>
            </div>
            <div class="filter-select-items-cancel">
                <div>

                    <label for="filter">Filter : </label>
                    <select id="filter" [(ngModel)]="selectedFilter">
                        <option value="all">All</option>
                        <option value="notCancelled">Active</option>
                        <option value="cancelled">Inactive</option>
                    </select>
                </div>
            </div>

        </div>

        <div class="delete-banner" *ngIf="selectedItems.size > 0">
            {{ selectedItems.size }} Session(s) Selected |
            <button matTooltip=" Cancel Session(s)" matTooltipPosition="above" mat-icon-button color="white"
                (click)="cancelSelectedItems()">
                <mat-icon style="margin-bottom: 2px;">cancel</mat-icon>
                <u>Cancel Session(s)</u>
            </button>
        </div>
        <table [attr.mode]="mode" class="table-fixedheader" *ngIf="sessions?.length; else displayErrorMessage">
            <thead>
                <th class="edit" (click)="sort('startDate','status', $event)">
                    <span>Status</span>
                    <i class="material-icons" [ngClass]="{'active':isStatusAsc}"> arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('checkInCode','', $event)">
                    <span>Check-in Code</span>
                    <i class="material-icons" [ngClass]="{'active':isCheckInCodeAsc}"> arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('title','', $event)">
                    <span>Session Name</span>
                    <i class="material-icons" [ngClass]="{'active':isTitleAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('classId','', $event)"><span>Session ID</span>
                    <i class="material-icons" [ngClass]="{'active':isIdAsc}"> arrow_drop_down</i>
                </th>
                <th class="view">
                    <div>
                        <div (click)="sort('title','', $event)">
                            <span>Session Name</span>
                            <i class="material-icons" [ngClass]="{'active':isTitleAsc}"> arrow_drop_down</i>
                        </div>
                        <div (click)="sort('classId','', $event)">
                            <span>Session ID</span>
                            <i class="material-icons" [ngClass]="{'active':isIdAsc}"> arrow_drop_down</i>
                        </div>
                    </div>
                </th>
                <th class="edit" (click)="sort('startDate','startdate', $event)">
                    <span>Date</span>
                    <i class="material-icons" [ngClass]="{'active':isDateAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('startDate','starttime', $event)">
                    <span>Start / end time</span>
                    <i class="material-icons" [ngClass]="{'active':isTimeAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('location','', $event)">
                    <span>Location</span>
                    <i class="material-icons" [ngClass]="{'active':isLocationAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="$event?.stopPropagation()">
                    <span>Attendees</span>
                </th>
                <th class="edit" (click)="$event?.stopPropagation()">
                    <div *ngIf="!isInactiveSessionSelected() && !isSessionValid()">
                        <!-- <div *ngIf="!isInactiveSessionSelected(); else inActiveSessionsFilter"> -->
                        <input type="checkbox" [checked]="areAllSelectableRowsChecked()"
                            (change)="toggleSelectAll($event)" />
                    </div>
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let session of getFilteredItems() | orderBy: {property: column, direction: direction}"
                    [ngClass]="{ 'grayed-out': session.isCancelled }">
                    <td class="disable edit">
                        <div>
                            <span></span>
                            <span class="{{session?.startDate |classcolor : session?.endDate }}">
                                {{session?.startDate |classstatus : session?.endDate }}
                            </span>
                        </div>
                    </td>
                    <td class="edit">
                        <div class="normal">
                            {{session?.isCpeEligible===true ? session?.checkInCode:''}}
                        </div>
                    </td>
                    <td class="edit">
                        <div class="anchor">
                            <span matTooltip="This session is cancelled" [matTooltipDisabled]="!session.isCancelled"
                                (click)='!session.isCancelled && openDialog(session)'>{{session?.title}}</span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <div>
                                <span>{{session?.classId}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="view">
                        <div>
                            <span>{{session?.title}}</span>
                            <span>{{session?.classId}}</span>
                        </div>
                    </td>
                    <td class="default">
                        <div>
                            <span>
                                <input type="checkbox" class="example-margin" value="{{session?.eventSetting?.qa}}"
                                    name="qa" (change)="toggleEventChange($event, session?.eventSetting)" />
                            </span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><i class="material-icons">date_range</i></span>
                            <div>
                                <span>{{session?.startDate | dateFormat : 'mmddyyyy'}}</span>
                                <span>{{session?.startDate | dateFormat : 'fulldayname'}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><i class="material-icons">access_time</i></span>
                            <div>
                                <span>{{session?.startDate | timeFormat}} - </span>
                                <span>{{session?.endDate | timeFormat}} ET</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><img src="assets/icons/location-table.svg" alt=""></span>
                            <span>{{session?.location}} </span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <div>
                                <span>Participants</span>
                            </div>
                            <div>
                                <span>{{session?.participants || 0}}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="isCompletedOrCancelled(session?.startDate,session?.endDate, session.isCancelled);
                        else noCancelButton" class="cancel-check-btn">
                            <input type="checkbox" [checked]="selectedItems.has(session.id)"
                                [disabled]="session.isCancelled" (change)="toggleSelection(session)" />
                            <button matTooltip="Cancel Session" matTooltipPosition="above" mat-icon-button color="warn"
                                (click)="cancelItem(session)" [disabled]="session.isCancelled">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="showLoading; else displayErrorMessage">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<ng-template #displayErrorMessage>
    <p class="background-white m-t-xs noResults">
        <span class="m-l-lg">
            <error-message [errorMessage]="errorMessage"></error-message>
        </span>
    </p>
</ng-template>

<div *ngIf="showLoading">
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>

<ng-template #inActiveSessionsFilter>
    <input type="checkbox" [disabled]="true" />
</ng-template>

<ng-template #noCancelButton>
</ng-template>