<div class="notification-history-search-box p-md divider bottom p-b-sm p-t-sm">
   <div class="inline-block ">
      <span class="font-size-16 m-b-sm" *ngIf="filterInput?.filterType; else displayCount">{{filteredNotificationsResponse?.length}} scheduled notification 
         <span *ngIf="filterInput?.filterType === 'FROM'">
            between {{ filterInput?.startDate | dateFormat : 'flight' }} and {{ filterInput?.endDate | dateFormat : 'flight' }}
         </span>
         <span *ngIf="filterInput?.filterType !== 'FROM'">
            <ng-container *ngIf="filterInput?.filterType === 'CREATED_BY'; else displayOnMessage">
               by {{ filterInput?.selectedAutoCompleteValue }}
            </ng-container>
            <ng-template #displayOnMessage>
               on {{ filterInput?.startDate | dateFormat : 'flight' }}
            </ng-template>
         </span>
         </span>
      <ng-template #displayCount>
         <span class="font-size-16 m-b-sm">{{notificationsResponse?.length}} scheduled notifications</span>
      </ng-template>
   </div>
   <div class="button-border inline-block pull-right p-lg notification-filter" *ngIf="createdByUsersList?.length">
      <app-filter [filterInput]="filterInput"  (appliedDateFilter)="appliedDateFilter($event)" [displayFilter]="showFilter" [searchableOptions]="createdByUsersList" (clearFilter)="resetToDefault()" ></app-filter>
   </div>
</div>
<div *ngIf="hideSpinner; else displaySpinner">
   <div *ngIf="displayErrorMessage">
      <p class="background-white m-t-xs text-center">
          <span class="m-l-lg">
              <error-message></error-message>
          </span>
      </p>
   </div>
   <div class="border-bottom-grey p-md position-relative" *ngFor="let history of filteredNotificationsResponse; let i = index" >
      <ng-template #enableEditMode>
         <div class="p-l-sm edit-notification">
            <div class="inline-block">
               <div class="inline-block font-size-16 p-l-sm p-r-sm p-b-sm p-t-0">
                  <div *ngIf="history?.message?.alertBody !== null; else addAlert" class="inline-block">
                     <button matTooltip="Remove Alert" matTooltipPosition="above" mat-icon-button color="primary"
                     [disabled]="history?.message?.messageType !== '3'"
                     (click)="addRemoveToExistingNotification(i, true, true)">
                        <mat-icon>highlight_off</mat-icon>
                     </button>
                     Remove Alert
                  </div>
                  <ng-template #addAlert>
                     <div class="inline-block">
                        <button matTooltip="Add Alert" matTooltipPosition="above" mat-icon-button color="primary"
                        (click)="addRemoveToExistingNotification(i, true, false)">
                           <mat-icon>add_circle_outline</mat-icon>
                        </button>
                        Add Alert
                     </div>
                  </ng-template>
               </div>
               <div class="inline-block font-size-16 m-l-md  pull-right p-l-sm p-r-sm p-b-sm p-t-0">
                  <div *ngIf="history?.message?.notificationBody !== null; else addAnnouncement" class="inline-block">
                     <button matTooltip="Remove Announcement" matTooltipPosition="above" mat-icon-button color="primary"
                     [disabled]="history?.message?.messageType !== '3'"
                     (click)="addRemoveToExistingNotification(i, false, true)">
                        <mat-icon>highlight_off</mat-icon>
                     </button>
                     Remove Announcement
                  </div>
                  <ng-template #addAnnouncement >
                     <button matTooltip="Add Announcement" matTooltipPosition="above" mat-icon-button color="primary"
                     (click)="addRemoveToExistingNotification(i, false, false)">
                        <mat-icon>add_circle_outline</mat-icon>
                     </button>
                     <div class="inline-block">Add Announcement</div>
                  </ng-template>
               </div>
            </div>
            <div class=" inline-block update-notification position-absolute">
               <button matTooltip="Cancel" matTooltipPosition="above" mat-icon-button color="warn" (click)="editNotificationCancel(i)">
                  <mat-icon>close</mat-icon>
               </button>
               
               <button matTooltip="Save" [disabled]="
                  (history?.isNotFutureDated || (history?.message?.messageType === '1' ? (history?.message?.alertBody && history?.message?.alertBody === _filteredNotificationsResponse[i]?.message?.alertBody) :
                  history?.message?.messageType === '2' ? (history?.message?.msgTitle && history?.message?.notificationBody && history?.message?.msgTitle === _filteredNotificationsResponse[i]?.message?.msgTitle && history?.message?.notificationBody === _filteredNotificationsResponse[i]?.message?.notificationBody) : 
                  ((history?.message?.alertBody && history?.message?.alertBody === _filteredNotificationsResponse[i]?.message?.alertBody) && 
                  history?.message?.msgTitle && history?.message?.notificationBody && history?.message?.msgTitle === _filteredNotificationsResponse[i]?.message?.msgTitle && history?.message?.notificationBody === _filteredNotificationsResponse[i]?.message?.notificationBody)) && 
                  (history?.scheduleDate ===  _filteredNotificationsResponse[i]?.scheduleDate) && (history?.scheduleTime ===  _filteredNotificationsResponse[i]?.scheduleTime) && (history?.message?.messageType === _filteredNotificationsResponse[i]?.message?.messageType))" matTooltipPosition="above" mat-icon-button class="text-green"  (click)="updateNotification(i)">
                  <mat-icon>done</mat-icon>
               </button>
               <!-- 
                  <div class=" inline-block font-size-16 m-l-md">
                    <span>Remove Alert</span> 
                  </div> -->
            </div>
         </div>
         <div *ngIf="history?.message?.alertBody !== null">
            <div class="panel-row">
               <div class="textgreysmall">Alert Body <span class="required"> *</span></div>
               <div class="textgreysmall">{{  history?.message?.alertBody?.length  }} / 150</div>
            </div>
            <div class="panel-row">
               <textarea class="textarea jp-textarea-body panel-row-textgreysmall" rows="1" name="alert" id='alertBody' maxlength="150" value="" cols="50"
               [(ngModel)]="history.message.alertBody" placeholder="Add message" 
               maxlength="150"></textarea>
            </div>
         </div>
         <div *ngIf="history?.message?.notificationBody !== null" class="edit-announcement__message">
                  <div class="panel-row">
                     <div class="textgreysmall">Announcement Title <span class="required"> *</span></div>
                     <div class="textgreysmall">{{history?.message?.msgTitle?.length}} / 50</div>
                  </div>
                  <div class="panel-row">
                     <textarea class="textarea m-b-sm panel-row-textgreysmall" rows="1" cols="50" name="announceTitle" id='announceTitle' placeholder="Add title" maxlength="50"  [(ngModel)]="history.message.msgTitle">
                     </textarea>
                  </div>
                  <div class="panel-row">
                     <div class="textgreysmall">Announcement Body <span class="required"> *</span></div>
                     <div class="textgreysmall">{{characterCount}} / {{maxCharacters}}</div>
                  </div>
                  <div class="panel-row flex-direction-announce">
                  <ngx-editor-menu class="rich-text-tool" [editor]="editor"
                  [toolbar]="toolbar" > </ngx-editor-menu>
                  <form class="panel-row-textgreysmall" [formGroup]="form">
                     <ngx-editor formControlName="content" 
                     (ngModelChange)="onContentChange($event)" [editor]="editor" (keydown)="wordCounter($event)" [(ngModel)]="history.message.notificationBody" [disabled]="false" [placeholder]="'Add message'">
                  </ngx-editor>
                  </form>
               </div>
         </div>
         <div class="display-flex center-flex child-50 position-relative">
            <label class="history-text-label text-shade-gray">Scheduled</label>
            <div class="m-l-md">
               <div class="inline-block">
                  <span class="m-l-sm font-size-15 pull-left">
                              <mat-form-field floatLabel="always" >
                                 <input matInput [matDatepicker]="picker"  [(ngModel)]="history.scheduleDate"  [min]="minDate" [max]="maxDate"
                                readonly (click)="picker.open()" (ngModelChange)="sendDateChange(i)" >
                                 <mat-datepicker-toggle matSuffix [for]="picker" class="blue-color"></mat-datepicker-toggle>
                                 <mat-datepicker #picker></mat-datepicker>
                               </mat-form-field>
                  </span>
               </div>
            </div>
            <div class="m-l-md">
               <div class="position-relative">
                  <mat-form-field appearance="legacy" class="time_range position-relative" (click)="displaySendTimePicker = !displaySendTimePicker"
                    [floatLabel]="'never'">
                    <input matInput name="one-time-time" value="{{history?.scheduleTime | dateFormat :'missedAddedTime'}}" placeholder="00:00" autocomplete="off" readonly >
                    <mat-icon class="position-absolute right-0 top-5 font-size-16 blue-color">access_time</mat-icon>
                  </mat-form-field>
                  <app-time-picker [dateTime]="history?.scheduleTime" (cancel)="displaySendTimePicker=false" (update)="sendTimeChange($event, i)" *ngIf="displaySendTimePicker"
                  [minutesArr] = "minuteIntervalArr" class="sendTimePicker"></app-time-picker>
                </div>
            </div>
         </div>
         <div *ngIf="history?.isNotFutureDated" class=" history-text-label"> Please select a future date and/or time </div>
      </ng-template>
      <div *ngIf="!history?.editMode; else enableEditMode">
         <div class="position-absolute right-0">
            <button matTooltip="Edit" matTooltipPosition="above" mat-icon-button color="primary" (click)="editNotification(i)">
               <mat-icon>edit</mat-icon>
            </button>
            <button matTooltip="Delete" matTooltipPosition="above" mat-icon-button color="primary"  (click)="deleteNotification(i)">
               <mat-icon>delete</mat-icon>
            </button>
         </div>
         <div *ngIf="history?.message?.alertBody !== null">
            <label class="history-text-label text-shade-gray font-size-15">Alert</label>
            <p class="history-text-head m-t-lg m-b-sm font-size-16 overflow-wrap-break-word">{{history?.message?.alertBody}}</p>
         </div>
         <div *ngIf="history?.message?.notificationBody !== null">
            <label class="history-text-label text-shade-gray m-t-xl font-size-15">Announcement</label>
            <p class="history-text-head m-t-lg m-b-sm font-size-16 font-weight-bold overflow-wrap-break-word">{{ history?.message?.msgTitle }}</p>
            <!-- <p class="history-text-head m-t-lg m-b-sm overflow-wrap-break-word" [innerHTML]="history?.message?.notificationBody"> -->
            <p class="history-text-head m-t-lg m-b-sm overflow-wrap-break-word" [innerHTML]="sanitizedHtml(history?.message?.notificationBody)">
         </p>
         </div>
         <div class="display-flex-box m-t-xl">
            <div class="display-flex center-flex m-b-sm">
               <div class="display-flex center-flex child-50">
                  <label class="history-text-label text-shade-gray">Scheduled</label>
                  <div class="m-l-md">
                     <span><img src="assets/icons/date-table.svg" alt=""></span>
                     <div class="inline-block">
                        <span class="m-l-sm font-size-15 pull-left">{{history?.scheduleDateTime | dateFormat : 'overviewdate'}}</span>
                     </div>
                  </div>
                  <div class="m-l-md">
                     <img src="assets/icons/time-table.svg" alt="">
                     <div class="inline-block">
                        <span class="m-l-sm font-size-15 pull-left">{{history?.scheduleDateTime | timeFormat }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="display-flex center-flex child-50 m-t-xl">
               <label class="history-text-label text-shade-gray">Created by</label>
               <div class="font-size-14 m-l-md">{{history?.message?.createdBy}}</div>
            </div>
            <div class="display-flex center-flex m-b-sm m-t-xl">
               <div class="display-flex center-flex child-50">
                  <label class="history-text-label text-shade-gray">Cateory</label>
                  <div class="chip font-size-14">{{history?.category }}</div>
               </div>
               <div class="display-flex center-flex child-50 position-absolute right-15 text-cyan-blue cursor-pointer">
                  <div (click)="toggleRecipentsAttribute(i)">
                     <span  *ngIf="history?.showAttributes; else displayShowMessage"> Hide recipients attributes </span>    
                     <ng-template #displayShowMessage>
                        <span> Show recipients attributes </span>
                     </ng-template>
                  </div>
               </div>
            </div>
         </div>
         <div catvaluesselected class="border" *ngIf="history?.showAttributes">
            <div class="category-container">
               <div class="m-sm" *ngIf="history?.displaySpinner; else showRecipentsAttribute">
                  <div class="m-t-lg text-center">
                     <mat-spinner [diameter]="50" class="margin-auto"></mat-spinner>
                   </div>
               </div>
               <ng-template #showRecipentsAttribute>
                  <div *ngFor="let attr of history?.recipentsAttribute" class="m-sm">
                     <div class="background-color-solitude border-radius-16 category-item p-t-sm p-r-sm p-b-sm p-l-sm w-fit-content">
                        <div>
                           <div class="category-item-overflow textbluesmall">
                              {{ attr?.name }}
                           </div>
                           <div *ngIf="history?.category !== 'Program'; else displayProgramMessageAttribute">
                              <div class="category-item-overflow textbluesmall" *ngIf="attr?.dtStart">
                                 <span class="text-gray-light" >{{ attr?.dtStart | dateFormat : 'mm/dd/yyyy' }} • {{ attr?.dtStart  | timeFormat }} 
                                    <ng-container *ngIf="attr?.dtEnd"> - {{ attr?.dtEnd  | timeFormat }}</ng-container>
                                    <ng-container *ngIf="attr?.id && history?.displayId"> • {{ attr?.id }}</ng-container>
                                    </span>
                              </div>
                           </div>
                           <ng-template #displayProgramMessageAttribute>
                              <div class="category-item-overflow textbluesmall" *ngIf="attr?.dtStart">
                                 <span class="text-gray-light" >{{ attr?.dtStart | dateFormat : 'mm/dd/yyyy' }}
                                    <ng-container *ngIf="attr?.dtEnd"> - {{ attr?.dtEnd | dateFormat : 'mm/dd/yyyy' }}</ng-container>
                                    </span>
                              </div>
                           </ng-template>
                        </div>
                     </div>
                  </div>
               </ng-template>
               
            </div>
         </div>
      </div>
   </div>
</div>
<ng-template #displaySpinner>
   <div class="m-t-lg">
      <mat-spinner [diameter]="50" class="margin-auto"></mat-spinner>
    </div>
</ng-template>
