import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProgramService } from '../../../../services/aws/program/program.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { IClass } from '../../../../models/IProgram';
import { MatDialog } from '@angular/material/dialog';
import { ClassDetailDialogComponent } from '../../../../widgets/dialog/class-detail-dialog.component';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ProgramState } from '../store/program.state';
import { Store } from '@ngxs/store';
import { ProgramActions } from '../store/program.action';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ReportService } from 'src/app/services/aws/report/report.service';
import { ParticipantService } from 'src/app/services/aws/participant/participant.service';
@Component({
    selector: 'program-classes',
    templateUrl: './classes.component.html',
    styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit, OnDestroy {

    @Input()
    parentSpinner: boolean;

    mode: string = 'view';
    searchmode: string = 'open';
    public errorMessage: IErrorMessage = {};
    classes: IClass[];
    _classes: IClass[];
    programId: any;
    popover: boolean = false;
    isDesc: boolean = false;
    column: string = 'startDate';
    direction: number = 1
    isTitleAsc: boolean = false;
    isIdAsc: boolean = false;
    public checkInCode: boolean = false;
    checkedInUsersCount: boolean = false;
    isDateAsc: boolean = false;
    isStatusAsc: boolean = false;
    isTimeAsc: boolean = false;
    isLocationAsc: boolean = false;
    isParticipantSelected: boolean;
    isClassSelected: boolean;
    isDisplay: boolean = true;
    showLoading: boolean = false;
    checkAll: boolean = false;
    isSaved: boolean = false;
    public email: string = '';
    private isAdminRole: boolean;

    constructor(private programService: ProgramService, private route: ActivatedRoute, private dialog: MatDialog, private store: Store,
        private auth: AuthenticationService, private router: Router, private reportService: ReportService, private participantService: ParticipantService) {
        this.isParticipantSelected = false;
        this.isClassSelected = false;
        this.route.params
            .pipe(take(1))
            .subscribe(params => {
                this.programId = params["id"];
            });
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
            const route = this.isAdminRole ? 'programs' : 'programs-external';
            if (!this.programService.isProgramModalOpen && val?.navigationTrigger === 'popstate' && val?.url.split(`${route}/${this.programId}`)[1] !== 'Classes') {
                const id = decodeURIComponent(val?.url.split(`${route}/${this.programId}`)[1])?.replace('/', '');
                const classId = this.classes.find((value: IClass) => value.id == id);
                if (classId) {
                    this.openDialog('classroster', classId.id, classId.startDate, classId?.classSetting?.facilitatorAttendance);
                }
            }
        });
    }

    ngOnInit() {
        this.isAdminRole = (this.router.url.startsWith('/admin/programs-external') ? false : true);
        if (this.isAdminRole) {
            this.getClassesByProgramId(this.programId);
        } else {
            this.auth.getUserEmail().pipe(take(1)).subscribe(e => {
                this.email = e.toString();
                this.getClassesByProgramId(this.programId, this.email);
            });
        }
    }

    getClassesByProgramId(programId, emailId: string = null) {
        this.errorMessage = {};
        this.showLoading = this.parentSpinner;
        const classService = this.isAdminRole ?
            this.programService.getClassesByProgram(programId) :
            this.participantService.getExternalFacilitatorCourses(programId);

        classService
            .subscribe((response: IClass[]) => {
                this.showLoading = false;
                this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                let sortResponse = this.programService?.programSort(response)?.map(item => ({
                    ...item,
                    checkedInUsersCount: item.checkedInUsersCount != null
                        ? item.checkedInUsersCount.toString() : '0'
                }));
                this.classes = sortResponse;
                this._classes = JSON.parse(JSON.stringify(this.classes));
                this.validateIfCheckinCodeExist();
                this.checkAll = response?.length > 0 && response?.every((c: any) => c.classSetting && c.classSetting.facilitatorAttendance);
            },
                () => {
                    this.showLoading = false;
                    this.classes = [];
                    this._classes = JSON.parse(JSON.stringify(this.classes));
                    this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                }
            );
    }

    private validateIfCheckinCodeExist(): void {
        this.classes?.forEach((value: IClass) => {
            if (value) {
                value.checkInCode = value?.checkInCode || 'N/A';
            }
        });
    }

    private getList() {
        this.getClassesByProgramId(this.programId);
    }

    toggleclass() {
        this.mode = this.mode != 'edit' ? 'edit' : 'view';
        this.searchmode = this.searchmode != 'close' ? 'close' : 'open';
    }

    sort(property, name, event) {
        event.stopPropagation();
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'title':
                this.isTitleAsc = !this.isTitleAsc;
                break;
            case 'checkInCode':
                this.checkInCode = !this.checkInCode;
                break;
            case 'classId':
                this.isIdAsc = !this.isIdAsc;
                break;
            case 'checkedInUsersCount':
                this.checkedInUsersCount = !this.checkedInUsersCount;
                break;
            case 'startDate':
                {
                    if (name == 'status') this.isStatusAsc = !this.isStatusAsc;
                    else if (name == 'starttime') this.isTimeAsc = !this.isTimeAsc;
                    else this.isDateAsc = !this.isDateAsc;
                    break;
                }
            case 'location':
                this.isLocationAsc = !this.isLocationAsc;
                break;
        }
    }

    checkUncheckAll(event) {
        this.checkAll = event.target.checked;
        this.errorMessage = {};
        this.classes.forEach((classInfo: IClass) => {
            const setting = {
                id: classInfo.classSetting ? classInfo.classSetting.id : classInfo.id,
                eventId: classInfo.id,
                facilitatorAttendance: this.checkAll
            } as IClass;
            classInfo.classSetting = setting;
            this.programService.updateClass(setting);
        });
        this.validateIfCheckinCodeExist();
        this.checkAll = this.classes?.length > 0 && this.classes?.every((c: any) => c.classSetting && c.classSetting.facilitatorAttendance);
        this.isDisplay = false;
    }

    saveClass() {
        this.store.dispatch(new ProgramActions.ClearClasses).subscribe(() => { });
        if (this.programService.isDirty('class')) {
            this.programService.save(this.programId, 'class').pipe(take(1)).subscribe(
                () => {
                    this.getClassesByProgramId(this.programId, this.email);
                    this.popOverMessage();
                });
        }

        this.programService.cancelUpdate('class');
    }


    keepediting() {
        this.popover = false;
    }

    cancel(value) {
        this.popover = value;
        if (!value) {
            this.isDisplay = true;
            this.programService.cancelUpdate('class');
            this.isParticipantSelected = false;
            this.isClassSelected = false;
            this.classes = JSON.parse(JSON.stringify(this._classes));
            this.validateIfCheckinCodeExist();
            this.checkAll = this.classes?.length > 0 && this.classes?.every((c: any) => c.classSetting && c.classSetting.facilitatorAttendance);
        }

    }

    openDialog(componentname, classId, startDate, facilitatorAttendance) {
        const dialogRef = this.dialog.open(ClassDetailDialogComponent,
            {
                data: {
                    componentname: componentname,
                    programId: this.programId,
                    classId: classId,
                    startDate: startDate,
                    facilitatorAttendance: facilitatorAttendance
                },
                panelClass: 'class-roster-dialog'
            });
        this.programService.isProgramModalOpen = true;
        this.routeCheck(this.isAdminRole);
        dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
            this.getList();
            setTimeout(() => {
                this.routeCheck(this.isAdminRole);
                this.programService.isProgramModalOpen = false;
            }, 30);
        });
    }

    private routeCheck(isAdminRole: boolean): void {
        if (isAdminRole) {
            this.router.navigate([`/admin/programs/${this.programId}/classes`]);
        } else {
            this.router.navigate([`/admin/programs-external/${this.programId}/classes`]);
        }
    }

    toggleAttendance(classInfo: any, index: number): void {
        const setAttendance = {
            id: classInfo.classSetting ? classInfo.classSetting.id : null,
            eventId: classInfo.id,
            facilitatorAttendance: classInfo.classSetting ? !classInfo.classSetting.facilitatorAttendance : true
        } as IClass;
        classInfo.classSetting = setAttendance;
        this.programService.updateClass(setAttendance);

        this.errorMessage = {};
        this.classes[index].classSetting = setAttendance;
        this.checkAll = setAttendance.facilitatorAttendance && this.classes.length > 0 && this.classes.every((c: any) => c.classSetting && c.classSetting.facilitatorAttendance);
        this.validateIfCheckinCodeExist();
        this.isDisplay = false;
    }

    public ngOnDestroy(): void {
        this.programService.clearClassCache();
    }

    private popOverMessage() {
        this.isDisplay = true;
        this.isSaved = true;
        this.toggleclass();
        setTimeout(() => {
            this.isSaved = false,
                this.toggleclass();
        }, 2000);
    }

    public downloadReport(): void {
        this.reportService.getEventReport(this.programId, "class", 'ClassesReport');
    }

}
