import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Dropdown } from '../../../../models/Dropdown';
import { map, Observable, of, take } from 'rxjs';
import { ProgramState } from '../store/program.state';
import { Select, Store } from '@ngxs/store';
import { ProgramActions } from '../store/program.action';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { IProgram } from 'src/app/models/IProgram';
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgramService } from 'src/app/services/aws/program/program.service';
import { ProgramDocumentsComponent } from '../program-documents/program-documents.component';
import { RegistrationComponent } from '../registration/registration.component';
import { SessionsComponent } from '../sessions/sessions.component';
import { ClassesComponent } from '../classes/classes.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { OverviewComponent } from '../overview/overview.component';
import { canComponentExit } from 'src/app/services/guard/un-saved-changes.guard';
import { NavMenuService } from 'src/app/services/nav-menu/nav-menu.service';

@Component({
    selector: 'admin-program',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnDestroy, OnInit, canComponentExit {
    @ViewChild(ProgramDocumentsComponent)
    programDocumentsComponent!: ProgramDocumentsComponent;

    @ViewChild(RegistrationComponent)
    RegistrationComponent!: RegistrationComponent;

    @ViewChild(SessionsComponent)
    sessionsComponent!: SessionsComponent;

    @ViewChild(ClassesComponent)
    classesComponent!: ClassesComponent;

    @ViewChild(OverviewComponent)
    overviewComponent!: OverviewComponent;

    public showGlobalSpinnerParent: boolean = false;
    breadcrumbdropdowndata: any;
    program: IProgram;
    @Select(ProgramState.programOverview) program$: Observable<any>;
    getProgramName$: Observable<any>;
    public isAdminRole: boolean;
    public setTabActive: number;
    breadcrumbOptions;
    tabOptions = {
        dropdowns: [],
        tabs: [
            { label: 'program-overview' },
            { label: 'classes' },
            { label: 'sessions' },
            { label: 'registration-status' },
            { label: 'program-documents' }
        ]
    };
    programDocValue: boolean;
    sessionCancelValue: boolean
    programId: string;
    public email: string = '';
    sessionCount: number = 0;

    sessionCountReceived(childCompValue: number) {
        this.sessionCount = childCompValue;
    }

    constructor(private store: Store, private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog, private programService: ProgramService,
        private auth: AuthenticationService, private navMenuService: NavMenuService) {
        this.isAdminRole = (this.router.url.startsWith('/admin/programs-external') ? false : true);
        this.breadcrumbOptions = {
            breadcrumb: [
                {
                    label: 'Dashboard',
                    itemclass: 'Breadcrumb-item',
                    linkclass: 'Breadcrumb-link',
                    link: this.isAdminRole ? '/admin/dashboard' : '#'
                },
                {
                    label: 'Programs',
                    itemclass: 'Breadcrumb-item',
                    linkclass: 'Breadcrumb-link',
                    link: this.isAdminRole ? '/admin/programs' : '/admin/programs-external'
                }
            ],
            dropdowns: [
                new Dropdown("Innovative Creative Learning(11/04/2019 - 11/06/2019)", "/admin/programs/1"),
                new Dropdown("Business Entertprose Training(11/04/2019 - 11/06/2019)", "/admin/programs/2"),
                new Dropdown("SAU Live(11/04/2019 - 11/06/2019)", "/admin/programs/3"),
                new Dropdown("Partner Meeting(11/04/2019 - 11/06/2019)", "/admin/programs/4"),
            ]
        };

        const facilitatorTabOptions = {
            dropdowns: [],
            tabs: [
                {
                    label: 'Classes',
                    activeTab: 'Classes'
                },
            ]
        };

        this.tabOptions = this.isAdminRole ? this.tabOptions : facilitatorTabOptions;
    }
    canLeaveComponent(nextState: string): boolean | Observable<boolean> {
        if (this.programDocValue || this.sessionCancelValue) {
            return this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: "",
                    body: 'Are you sure you want to exit and lose changes?',
                    buttonTextNo: "Keep Editing",
                    buttonTextYes: "Exit"
                },
                panelClass: 'notification-submit-dialog'
            }).afterClosed()
                .pipe(map(choice => {
                    if (choice) {
                        return true;
                    }
                    else if (!choice && nextState === '/admin/programs-external') {
                        this.navMenuService.isFacilitatorView();
                        this.navMenuService.disableDropdownMenu$.next('admin');
                    }
                    return false;
                }));
        }
        else {
            return true;
        }
    };

    public ngOnInit(): void {
        this.router.events
            .subscribe((event: NavigationStart) => {
                if (event.navigationTrigger === 'popstate') {
                    const route = event?.url?.replace('%20', ' ')?.split('/');
                    this.setTabActive = this.tabOptions.tabs.findIndex(x => x?.label === route[route?.length - 1]);
                }
            });
        this.activatedRoute.paramMap.subscribe((data) => {

            let currentRoute = this.programTabOption(data.get('type'))
            this.setTabActive = currentRoute;
            this.getProgramOverview();
        });

    }
    // Commented Unused method as logging Errors in developer-mode
    // public ngAfterViewChecked(): void {
    //     var route =  this.router?.url?.replace('%20', ' ')?.split('/');
    //     this.setTabActive = this.tabOptions.tabs.findIndex(x => x?.label === route[route?.length - 1]);                     
    // }

    public ngOnDestroy(): void {
        this.store.dispatch(new ProgramActions.ClearProgramOverview());
    }

    getProgramDocFormStatus(isFormOpen: boolean) {
        this.programDocValue = isFormOpen;
    }

    getCancelBannerStatus(val: boolean) {
        this.sessionCancelValue = val;
    }

    public navigateToTab(event): void {

        if (this.programDocValue && this.setTabActive !== 4) {

            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: "",
                    body: 'Are you sure you want to exit and lose changes?',
                    buttonTextNo: "Keep Editing",
                    buttonTextYes: "Exit"
                },
                panelClass: 'notification-submit-dialog'
            }).afterClosed()
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.setTabActive = event.index;
                        this.programDocValue = false;
                    }
                });

            this.setTabActive = 4;

        }
        else if (this.sessionCancelValue && this.setTabActive !== 2) {
            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: "",
                    body: 'Are you sure you want to exit and lose changes?',
                    buttonTextNo: "Keep Editing",
                    buttonTextYes: "Exit"
                },
                panelClass: 'notification-submit-dialog'
            }).afterClosed()
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.setTabActive = event.index;
                        this.sessionCancelValue = false;
                    }
                });

            this.setTabActive = 2;
        }
        else {
            this.fetchDataOnTabChange(event.index);
        }

    }


    public getProgramOverview(): void {
        this.programId = this.router.url
            ?.split("/admin/programs/")[1]
            ?.split("/")[0];

        this.store.dispatch(new ProgramActions.GetProgramsOverview(this.programId))
            .pipe(take(1))
            .subscribe((event) => {
                this.getProgramName$ = of(event?.programs?.programOverview?.name);
            })
    }


    fetchDataOnTabChange(tabValue: number) {

        this.setTabActive = tabValue;
        this.showGlobalSpinnerParent = true;
        switch (tabValue) {
            case 0:
                this.overviewComponent.getProgramOverview(this.programId);
                this.overviewComponent.getProgramEvents(this.programId);
                this.router.navigate([`../program-overview`], { relativeTo: this.activatedRoute });
                break;
            case 1:
                if (this.isAdminRole) {
                    this.classesComponent.getClassesByProgramId(this.programId);
                } else {
                    this.auth.getUserEmail().pipe(take(1)).subscribe(e => {
                        this.email = e.toString();
                        this.classesComponent.getClassesByProgramId(this.programId, this.email);
                    });
                }
                this.router.navigate([`../classes`], { relativeTo: this.activatedRoute });
                break;
            case 2:
                if (!this.sessionCancelValue) {
                    this.sessionsComponent.getClasses(this.programId);
                    this.router.navigate([`../sessions`], { relativeTo: this.activatedRoute });
                }
                break;
            case 3:
                this.RegistrationComponent.getList();
                this.router.navigate([`../registration-status`], { relativeTo: this.activatedRoute });
                break;
            case 4:
                if (!this.programDocValue) {
                    this.programDocumentsComponent.getAllProgramDocs();
                    this.router.navigate([`../program-documents`], { relativeTo: this.activatedRoute });
                }
                break;
            default:
                this.router.navigate([`../program-overview`], { relativeTo: this.activatedRoute });
                break;

        }

    }

    programTabOption(routeName: String): number {

        switch (routeName) {
            case "program-overview": return 0;
            case "classes": return 1;
            case "sessions": return 2;
            case "registration-status": return 3;
            case "program-documents": return 4;
            default: return 0;
        }

    }

}
