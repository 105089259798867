<div class="timepicker" [attr.show]="show">
    <div class="time-header">Time</div>
    <mat-form-field class="time-field">
        <mat-label>Hour</mat-label>
        <input matInput type="number" [(ngModel)]="hour" min="1" max="12" (blur)="validateHour()" />
    </mat-form-field>
    <mat-form-field class="time-field">
        <mat-label>Minute</mat-label>
        <input matInput type="number" [(ngModel)]="minute" min="0" max="59" (blur)="validateMinute()" />
    </mat-form-field>
    <mat-form-field class="time-field ampm-field">
        <mat-label>AM/PM</mat-label>
        <input 
            matInput 
            type="text" 
            [(ngModel)]="ampm" 
            (blur)="validateAmPm()" 
            (keydown)="toggleAmPmKey($event)" 
            class="ampm-input"
        />
    </mat-form-field>
    <div class="time-footer">
        <div class="time-buttons">
            <button type="button" class="btn" (click)="cancelClick()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="updateClick()">Done</button>
        </div>
    </div>
</div>