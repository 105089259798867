import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IProgram, IClass, ISession, IProgramDocs } from '../../../models/IProgram';
import { IParticipant } from '../../../models/IParticipant';
import { IClassRoster, IFacilitators, IParticipants } from '../../../models/IClassRoster';
import { environment } from '../../../../environments/environment';
import { API } from 'aws-amplify';
import { FILTER_TYPE, IFilterRequest } from 'src/app/shared/filter/filter.interface';
import { ApiConfigurationsService, IRequest } from 'src/app/services/shared/api-configurations.service';
import { ReportService } from '../report/report.service';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class ProgramService {
    private programCache = new Map();
    private classCache = new Map();
    private sessionCache = new Map();
    public isSesionsModalOpen: boolean = false;
    public isProgramModalOpen: boolean = false;

    constructor(private http: HttpClient, private apiConfigurationsService: ApiConfigurationsService, private reportsService: ReportService) { }

    public getProgramsList(): Observable<IProgram[]> {
        // initiate reports lambda to make hot - performance improvement
        this.callReportsLambda();
        const getFromQueue: boolean = true;
        if (getFromQueue) {
            const body: IRequest = {
                apiConfigurations: this.apiConfigurationsService.getConfigurations('GET_PROGRAMS')
            };
            return this.apiConfigurationsService.get(body).pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((data: IProgram[]) => {
                    return this.sortPrograms(data) as IProgram[];
                }));
        } else {
            return from(API.get('portal', 'programs', {})).pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map(data => {
                    return this.sortPrograms(data) as IProgram[];
                })
            );
        }
    }

    private sortPrograms(programs: IProgram[]): IProgram[] {
        const data = JSON.parse(JSON.stringify(programs));
        data.sort((a, b) => {
            const aCompare = a['name'];
            const bCompare = b['name'];
            return aCompare.localeCompare(bCompare, undefined, { numeric: true, sensitivity: 'base' });
        });
        data.sort((a, b) => {
            const aStartDate: Date = new Date(a.startDate);
            const bStartDate: Date = new Date(b.startDate);
            const aEndDate: Date = new Date(a.endDate);
            const bEndDate: Date = new Date(b.endDate);
            const endDateValue = aEndDate < bEndDate ? -1 : aEndDate > bEndDate ? 1 : 0;
            return aStartDate < bStartDate ? -1 : aStartDate > bStartDate ? 1 : endDateValue;
        });
        return data;
    }

    callReportsLambda() {
        this.reportsService.getReport('flightArrivals').subscribe();
    }

    get(programId): Observable<any> {
        return from(API.get('portal', 'programs/' + programId, {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IProgram) => {

                const { cEventId } = data;
                const ceventRegistrationUrl = data?.programSetting?.ceventRegistrationUrl;
                const baseUrl = environment.cvent;

                let actualCventUrl = `${baseUrl}${cEventId}`
                data = {
                    ...data,
                    "programSetting": {
                        ...data["programSetting"],
                        "ceventRegistrationUrl": !ceventRegistrationUrl ? actualCventUrl : ceventRegistrationUrl
                    }
                };
                return data as IProgram;
            })
        );
    }

    public clearClassCache(): void {
        this.classCache.clear();
    }

    public filterProgramsBySelectedDate(programs: IProgram[], _programs: IProgram[], filterInput: IFilterRequest): IProgram[] {
        // This function is being used in multiple places. EX: programs, house and well being events
        programs = [];
        switch (filterInput.filterType) {
            case FILTER_TYPE.FROM:
                _programs.forEach((program: IProgram) => {
                    let startDate: Date = new Date(program?.startDate);
                    let endDate = new Date(program?.endDate);
                    const today = new Date();
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);
                    today.setHours(0, 0, 0, 0);
                    let filterStartDate = filterInput?.startDate;
                    let filterEndDate = filterInput?.endDate;

                    const tempStartDate = startDate;
                    const tempFilterStartDate = filterInput?.startDate;

                    if (startDate < filterStartDate && endDate >= tempFilterStartDate) {
                        startDate = filterStartDate;
                        filterStartDate = tempStartDate;
                    }

                    if ((startDate >= filterStartDate && startDate <= filterEndDate) ||
                        (endDate >= filterStartDate && endDate <= filterEndDate)) {
                        programs.push(program);
                    }
                });
                break;
            case FILTER_TYPE.ON:
            case FILTER_TYPE.TODAY:
                _programs.forEach((program: IProgram) => {
                    const startDate: Date = new Date(program?.startDate);
                    const endDate = new Date(program?.endDate);
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);
                    const filterInputStartDate: Date = new Date(filterInput?.startDate);
                    const filterInputEndDate = new Date(filterInput?.endDate);
                    filterInputStartDate.setHours(0, 0, 0, 0);
                    filterInputEndDate.setHours(0, 0, 0, 0);
                    if (((filterInputStartDate >= startDate && endDate >= filterInputEndDate))) {
                        programs.push(program);
                    }
                });
                break;
        }
        let sortResponse = this.programSort(programs);
        return sortResponse;
    }

    getClassesByProgram(programId): Observable<IClass[]> {
        return from(API.get('portal', 'programs/' + programId + "/courses", {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IClass[]) => {
                data = data.map(c => {
                    c.location = c.location || 'TBD';
                    return c;
                });
                data.sort((a, b) => {
                    return (new Date(a.startDate) > new Date(b.startDate) ? -1 : 0) * -1 || a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' });
                });
                return data as IClass[];
            })
        );
    }

    getSessionsByProgram(programId): Observable<ISession[]> {
        return from(API.get('portal', 'programs/' + programId + "/sessions", {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map(data => {
                data = data.map(s => {
                    s.location = s.location || 'TBD';
                    return s;
                });
                data.sort((a, b) => {
                    return (new Date(a.startDate) > new Date(b.startDate) ? -1 : 0) * -1 || a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' });
                });
                return data;
            })
        );
    }

    getParticipantsByProgram(programId): Observable<IParticipant> {
        return from(API.get('portal', 'programs/' + programId + "/registrationStatus", {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map(data => {
                data.sort((a, b) => {
                    return a.lastName.localeCompare(b.lastName, undefined, { numeric: true, sensitivity: 'base' }) || a.firstName.localeCompare(b.firstName, undefined, { numeric: true, sensitivity: 'base' });
                });
                return data as IParticipant;
            })
        );
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    patchClassesDisplayOptions(classes: IClass[]) {
        return this.http.patch('classes', classes);
    }

    patchSessionsDisplayOptions(sessions: ISession[]) {
        return this.http.patch('sessions', sessions);
    }

    patchProgramsUrlOptions(programs: IProgram) {
        return this.http.patch('programs', programs);
    }

    getClassDetail(programId, classId) {
        return from(API.get('portal', 'programs/' + programId + "/courses/" + classId, {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IClassRoster[]) => {
                return data as IClassRoster[];
            })
        );
    }

    getRosterParticipants(programId, classId) {
        const getFromQueue: boolean = true;
        if (getFromQueue) {
            const body: IRequest = {
                apiConfigurations: this.apiConfigurationsService.getConfigurations('GET_CLASS_ROASTER_PARTICIPANTS')
            };
            body.apiConfigurations.PATH = body.apiConfigurations.PATH.replace('${programId}', programId).replace('${courseId}', classId);
            return this.apiConfigurationsService.get(body).pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((data: IParticipants[]) => {
                    data = data.map(c => {
                        c.isEdit = false;
                        return c;
                    });
                    return data as IParticipants[];
                }));
        }
        return from(API.get('portal', 'programs/' + programId + "/courses/" + classId + "/participants", {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IParticipants[]) => {
                data = data.map(c => {
                    c.isEdit = false;
                    return c;
                });
                return data as IParticipants[];
            })
        );
    }

    getRosterFacilitators(programId, classId) {
        return from(API.get('portal', 'programs/' + programId + "/courses/" + classId + "/facilitators?setting=true", {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IFacilitators[]) => {
                data = data.map(c => {
                    c.isEdit = false;
                    return c;
                });
                return data as IFacilitators[];
            })
        );
    }

    updateProgram(program: IProgram) {
        if (this.programCache.has(program.id)) {
            let programExits = this.programCache.get(program.id);
            if (program.urlTypeName === 'courseUrl') {
                programExits.courseRegistrationUrl = program.courseRegistrationUrl;
            }
            else {
                programExits.ceventRegistrationUrl = program.ceventRegistrationUrl;
            }
            this.programCache.delete(program.id);
            this.programCache.set(program.id, programExits);
        } else
            this.programCache.set(program.id, program);
    }

    saveUpdate() {
        return from(API.patch('portal', 'programs', { body: Array.from(this.programCache.values()) })).pipe(
            map(res => {
                return res as any;
            })
        );
    }

    cancelUpdate(name) {
        switch (name) {
            case 'program':
                this.programCache.clear();
                break;
            case 'class':
                this.classCache.clear();
                break;
            case 'session':
                this.sessionCache.clear();
                break;
        }
    }

    isDirty(name) {
        switch (name) {
            case 'program':
                return this.programCache.size > 0;
            case 'class':
                return this.classCache.size > 0;
            case 'session':
                return this.sessionCache.size > 0;
        }
    }

    updateClass(classInfo: IClass) {
        if (this.classCache.has(classInfo.id)) {
            let classExits = this.classCache.get(classInfo.id);
            classExits.facilitatorAttendance = classInfo.facilitatorAttendance;
            this.classCache.delete(classInfo.id);
            this.classCache.set(classInfo.id, classExits);
        }
        else
            this.classCache.set(classInfo.id, classInfo);
    }

    updateSession(sessionInfo: ISession) {
        if (this.sessionCache.has(sessionInfo.id)) {
            let sessionExits = this.sessionCache.get(sessionInfo.id);
            sessionExits.qa = sessionInfo.qa;
            this.sessionCache.delete(sessionInfo.id);
            this.sessionCache.set(sessionInfo.id, sessionExits);
        }
        else
            this.sessionCache.set(sessionInfo.id, sessionInfo);
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    save(programId, name) {
        const payload: any[] = Array.from(this.classCache.values());
        payload.forEach((value) => {
            if (value?.id === value?.eventId) {
                value.id = null;
            }
        });
        switch (name) {
            case 'class':
                return from(API.patch('portal', 'programs/' + programId + '/courses', { body: payload })).pipe(
                    map(res => { })
                );
            case 'session':
                return from(API.patch('portal', 'programs/' + programId + '/sessions', { body: payload })).pipe(
                    map(res => { })
                );
        }
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    programSort(programs: any[]) {
        return programs.sort((a, b) =>
            a?.hasOwnProperty('title') //Classes and Sessions Tab
                ?
                (+new Date(a?.startDate) - +new Date(b?.startDate)
                    ||
                    +new Date(a?.endDate) - +new Date(b?.endDate)
                    ||
                    a.title?.localeCompare(b.title))
                :
                a?.eventType == ("1" || "4") ? //House Event
                    (+new Date(a?.startDate) - +new Date(b?.startDate)
                        ||
                        +new Date(a?.endDate) - +new Date(b?.endDate)
                        ||
                        a.eventDetailsName?.localeCompare(b.eventDetailsName))
                    : //Dashboard and Programs
                    (+new Date(a?.startDate)?.setHours(0, 0, 0, 0) - +new Date(b?.startDate)?.setHours(0, 0, 0, 0)
                        ||
                        +new Date(a?.endDate)?.setHours(0, 0, 0, 0) - +new Date(b?.endDate)?.setHours(0, 0, 0, 0)
                        ||
                        a.name?.localeCompare(b.name)));
    }

    public getProgramDocuments(programId: any) {
        return from(API.get('portal', 'programs/documents?programId=' + programId, {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IProgramDocs[]) => {
                data = data.map((s: IProgramDocs) => {
                    s.isEditable = false;
                    s.readMore = false;
                    return s;
                });
                return data as IProgramDocs[];
            })
        );
    }

    public addProgramDocuments(programId: any, data: IProgramDocs): Observable<IProgramDocs> {
        data.url = data?.url?.trim();
        return from(API.post('portal', 'programs/documents?programId=' + programId, { body: data })).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((res: string) => {
                return res as any;
            })
        );
    }


    public deleteProgramDocument(programId: string, docId: string): Observable<IProgramDocs> {
        let data = null;
        return from(API.del('portal', 'programs/documents?programId=' + programId + '&documentId=' + docId, {})).pipe(
            map((res: string) => {
                return res as any;
            })
        );
    }


    public updateDocument(programId: string, docId: string, data: IProgramDocs): Observable<IProgramDocs> {
        data.url = data?.url?.trim();
        return from(API.put('portal', 'programs/documents?programId=' + programId + '&documentId=' + docId, { body: data })).pipe(
            map((res: string) => {
                return res as any;
            })
        )
    }

    public deleteSessions(programId: string, sessionIds: any): Observable<any[]> {
        const requestBody = {
            sessionIds: (sessionIds instanceof Set) ? [...sessionIds] : [sessionIds]
        };
        return from(API.del('portal', 'programs/' + programId + "/sessions", { body: requestBody })).pipe(
            map((res: string) => {
                return res as any;
            })
        )
    }
}