import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, Form, ValidationErrors, AbstractControl } from '@angular/forms';
import { catchError, delay, expand, map, take, takeUntil, takeWhile } from "rxjs/operators";
import { HouseEventService } from 'src/app/services/aws/house-and-well-being-events/house-event.service';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Observable, Subject, throwError } from "rxjs";
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { IHouseEvent, IEvent, IEventId, IHouseEventParticipants } from 'src/app/models/IHouseEvent';
import { UploadHouseEventsResponse } from './house-and-well-being-events.interface';
import { IFilterRequest, IFilterResponse } from 'src/app/shared/filter/filter.interface';
import { ProgramService } from 'src/app/services/aws/program/program.service';
import { UtilsService } from 'src/app/services/shared/utils.service';
import { NavMenuService } from 'src/app/services/nav-menu/nav-menu.service';
import { canComponentExit } from 'src/app/services/guard/un-saved-changes.guard';

@Component({
    selector: 'app-house-and-well-being-events',
    templateUrl: './house-and-well-being-events.component.html',
    styleUrls: ['./house-and-well-being-events.component.scss']
})
export class HouseAndWellBeingEventsComponent implements OnInit, OnDestroy, canComponentExit {

    public filterInput: IFilterRequest = {};

    private destroy$ = new Subject();
    showLoading: boolean;
    isDesc: boolean = false;
    column: string;
    direction: number;
    isNameAsc: boolean = false;
    isDateAsc: boolean = false;
    isTimeAsc: boolean = false;
    isDescriptionAsc: boolean = false;
    isTypeAsc: boolean = false;
    isRegisteredAsc: boolean = false;
    isCapacityAsc: boolean = false;
    isRoomAsc: boolean = false;
    isStatusAsc: boolean = false;
    isFilterRes: boolean = false;
    public fileNameMissMatch: boolean = false;
    public invalidEvents: UploadHouseEventsResponse[] = [];
    public invalidParticipants: IHouseEventParticipants[] = [];
    addUser!: FormGroup;
    filterRes: string = API_USER_DEFINED_MESSAGE.EMPTY_FILTER_RESPONSE;

    public invalidFileMessage = '';

    breadcrumbOptions = {
        breadcrumb: [
            {
                label: 'Dashboard',
                itemclass: 'Breadcrumb-item',
                linkclass: 'Breadcrumb-link',
                link: '/admin/dashboard'
            },
            {
                label: 'House & Well-being events',
                itemclass: 'Breadcrumb-item',
                linkclass: 'Breadcrumb-link',
                link: '/admin/settings/houseandwellbeingevents'
            }
        ],
    };

    public eventsTable: FormGroup;
    public control: FormArray;
    public mode: boolean;
    public touchedRows;
    public houseEvents: IHouseEvent[];
    public _houseEvents: IHouseEvent[];
    public isCopyRow: boolean;
    public showUploadFile: boolean = false;
    public showEventAttUpload: boolean = false;
    public disableUploadCta: boolean = true;
    private hasUnSavedChanges = false;

    public errorMessage: IErrorMessage = {};
    public fileEvent;
    public disableDeleteEditButons: boolean[] = [];
    public houseEventParticipants: IHouseEventParticipants[] = [];
    public overlayDetails;
    public overlayData;
    public activityID: IEventId;
    public capacity: IEvent;
    public isEditable: boolean = true;
    showParticipantList: boolean = false;
    isOVNameAsc: boolean = false;
    isOVEmailAsc: boolean = false;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    fileName = 'Choose File';
    file;
    fileError: boolean = false;
    minDate: Date;
    selectAll: boolean = false;
    selectedRows: Set<number> = new Set();
    addParticipant: boolean = false;
    inputEmail: string = "";
    pastEvent: boolean = false;

    allowedFile: string[] = ["xlsx", "csv"];
    public showGlobalSpinner: boolean = false;

    constructor(private fb: FormBuilder, private houseEventService: HouseEventService, private dialog: MatDialog, private snackBar: MatSnackBar,
        private programService: ProgramService, private utilsService: UtilsService,
        private navMenuService: NavMenuService) {
        let currentEstDateTime = moment()
            .tz("America/New_York")
            .format("YYYY-MM-DD HH:mm:ss");
        this.minDate = new Date(currentEstDateTime);
    }
    canLeaveComponent(nextState: string): boolean | Observable<boolean> {
        if (this.inputEmail?.trim() || this.addParticipant || this.showUploadFile || this.showEventAttUpload) {
            return this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: "",
                    body: 'Are you sure you want to exit and lose changes?',
                    buttonTextNo: "Keep Editing",
                    buttonTextYes: "Exit"
                },
                panelClass: 'notification-submit-dialog'
            }).afterClosed()
                .pipe(map(choice => {
                    if (choice) {
                        return true;
                    }
                    else if (!choice && nextState === '/admin/programs-external') {
                        this.navMenuService.isFacilitatorView();
                        this.navMenuService.disableDropdownMenu$.next('admin');
                    }
                    return false;
                }));
        }
        else {
            return true;
        }
    };

    private get tableRows(): FormArray {
        return this.eventsTable.get('tableRows') as FormArray;
    }

    private scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    private setEventsTableValues(data): void {

        this.eventsTable.patchValue(data);

        const tableFormArray = (this.eventsTable.get('tableRows') as FormArray);

        while (tableFormArray.length) {
            tableFormArray.removeAt(0);
        }

        data.forEach(row => {
            row.startDate = new Date(row.startDate);
            row.endDate = new Date(row.endDate);
            tableFormArray.push(this.fb.group(row));
        });
    }

    public getAllHouseEvent(): void {
        this.houseEventService.getHouseEvent().pipe(
            takeUntil(this.destroy$),
            map((data: IHouseEvent[]) => data))
            .subscribe((response: IHouseEvent[]) => {
                this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this.houseEvents = response;
                this._houseEvents = response;
                this.setEventsTableValues(response);
                this.sort('default');
                this.validateDeleteEditButons();
            },
                () => {
                    this.houseEvents = [];
                    this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                });
    }

    ngOnInit() {
        this.touchedRows = [];
        this.eventsTable = this.fb.group({
            tableRows: this.fb.array([])
        });
        this.getAllHouseEvent();
        this.addUser = this.fb.group({
            email: ['', [Validators.required, this.emailValidator()]]
        })
    }

    emailValidator(): any {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value?.trim();
            const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            const isValid = emailPattern.test(value);
            return isValid ? null : { 'invalidEmail': true }

        }
    }

    noWhitespaceValidator(control: AbstractControl) {
        if (control.value && control.value.trim().length === 0) {
            return { whitespace: true };
        }
        return null;
    }

    private sort(property: string): void {
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'eventDetailName':
                this.isNameAsc = !this.isNameAsc;
                break;
            case 'startDate':
                this.isDateAsc = !this.isDateAsc;
                break;
            case 'startTime':
                this.isTimeAsc = !this.isTimeAsc;
                break;
            case 'eventDetailDescription':
                this.isDescriptionAsc = !this.isDescriptionAsc;
                break;
            case 'eventType':
                this.isTypeAsc = !this.isTypeAsc;
                break;
            case 'registered':
                this.isRegisteredAsc = !this.isRegisteredAsc;
                break;
            case 'capacity':
                this.isCapacityAsc = !this.isCapacityAsc;
                break;
            case 'functionRoomName':
                this.isRoomAsc = !this.isRoomAsc;
                break;
            case 'status':
                this.isStatusAsc = !this.isStatusAsc;
        }

        this.houseEvents.sort((a, b) => {
            let aCompare;
            let bCompare;
            if (property === 'default') {
                aCompare = a[property];
                bCompare = b[property];
                return b.status.localeCompare(a.status);
            }
            else if (property === 'startDate') {
                aCompare = a[property];
                bCompare = b[property];
                return (aCompare < bCompare ? -1 : (aCompare > bCompare ? 1 : 0)) * this.direction;
            }
            else {
                aCompare = a[property].toLowerCase();
                bCompare = b[property].toLowerCase();
            }
            let value = aCompare.localeCompare(bCompare, undefined, { numeric: true, sensitivity: 'base' });
            return (value === -1 ? -1 : (value === 1 ? 1 : 0)) * this.direction;
        });
        this.setEventsTableValues(this.houseEvents);
        this.validateDeleteEditButons();
    }

    ngAfterOnInit() {
        this.control = this.eventsTable.get('tableRows') as FormArray;
    }

    public appliedDateFilter(appliedFilter: IFilterResponse): void {
        appliedFilter.startDate.setHours(0, 0, 0, 0);
        appliedFilter.endDate.setHours(0, 0, 0, 0);
        const filteredResult = this.programService.filterProgramsBySelectedDate([], <any>this._houseEvents, appliedFilter);
        this.houseEvents = JSON.parse(JSON.stringify(filteredResult));
        this.setEventsTableValues(this.houseEvents);
        this.validateDeleteEditButons();
        this.isFilterRes = filteredResult.length ? false : true;
    }

    public resetToDefault(): void {
        this.isFilterRes = false;
        this.houseEvents = JSON.parse(JSON.stringify(this._houseEvents));
        this.setEventsTableValues(this.houseEvents);
        this.validateDeleteEditButons();
    }

    private initiateForm(group?: FormGroup): FormGroup {
        return this.fb.group({
            activityID: [group?.get('activityID').value || null],
            status: [group?.get('status').value || '1'],
            eventDetailName: [group?.get('eventDetailName').value || null, [Validators.required, this.noWhitespaceValidator]],
            eventDetailDescription: [group?.get('eventDetailDescription').value || null, [Validators.required, this.noWhitespaceValidator]],
            eventType: [group?.get('eventType').value || null, [Validators.required]],
            capacity: [group?.get('capacity').value || null, [Validators.required, Validators.pattern('^[1-9][0-9]*$')]],
            functionRoomName: [group?.get('functionRoomName').value || null, [Validators.required, this.noWhitespaceValidator]],
            isEditable: [true],
            readMore: [true],
            registered: [{ value: group?.get('registered').value || null, disabled: true }],
            startDate: [new Date(group?.get('startDate').value) || null, [Validators.required]],
            endDate: [new Date(group?.get('endDate').value) || null, [Validators.required]],
            startTime: [group?.get('startTime').value || null, [Validators.required]],
            endTime: [group?.get('endTime').value || null, [Validators.required]]
        });
    }

    public addRow(): void {
        this.isCopyRow = false;
        this.toggleuploadFileDiv('hideUploadFile')
        const control = this.eventsTable.get('tableRows') as FormArray;
        control.insert(0, this.initiateForm());
        this.hasUnSavedChanges = true;
        this.validateDeleteEditButons();
    }

    private cancelRow(index: number, group: FormGroup): void {
        this.hasUnSavedChanges = false;
        let activityID = group.get('activityID').value;
        if (activityID !== null && this.isCopyRow !== true) {
            group.get('isEditable').setValue(false);
            group.get('readMore').setValue(false);
        }
        else {
            const control = this.eventsTable.get('tableRows') as FormArray;
            control.removeAt(index);
        }
        this.setEventsTableValues(this.houseEvents);
        this.validateDeleteEditButons();
    }

    private deleteRow(index: number, group: FormGroup): void {
        this.isCopyRow = false;
        this.toggleuploadFileDiv('hideUploadFile');
        this.openConfirmDialog("Are you sure you want to delete this event?")
            .pipe(take(1))
            .subscribe(result => {
                if (result) {

                    this.showGlobalSpinner = true;
                    let activityID = group.get('activityID').value;

                    this.houseEventService.deleteHouseEvent(activityID)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.showGlobalSpinner = false;
                            // const control = this.eventsTable.get('tableRows') as FormArray;
                            // control.removeAt(index);
                            group.get('status').setValue("0");
                            this.getAllHouseEvent();
                            this.snackBar.open('Event deleted successfully.', 'OK', {
                                duration: 5000,
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                                panelClass: ['success-snackbar']
                            });
                        },
                            () => {
                                this.showGlobalSpinner = false;
                                this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                                    duration: 8000,
                                    horizontalPosition: this.horizontalPosition,
                                    verticalPosition: this.verticalPosition,
                                    panelClass: ['error-snackbar']
                                });
                            });

                }
            });

    }

    private copyRow(group: FormGroup, index: number): void {
        this.isCopyRow = true;
        const control = this.eventsTable.get('tableRows') as FormArray;
        let getStatus = group.get('status').value;
        if (getStatus === '0') {
            group.get('status').setValue('1');
        }
        this.toggleuploadFileDiv('hideUploadFile')
        control.insert(index + 1, this.initiateForm(group));
        this.hasUnSavedChanges = true;
        this.validateDeleteEditButons();
    }

    private editRow(group: FormGroup): void {
        this.toggleuploadFileDiv('hideUploadFile');
        const control: FormArray = this.eventsTable.get('tableRows') as FormArray;
        const index = control.controls.findIndex(x => x.value == group.value);
        control.removeAt(index);
        control.insert(index, this.initiateForm(group));
        this.isCopyRow = false;
        this.hasUnSavedChanges = true;
        const currentControl: FormArray = control.controls[index] as FormArray;
        currentControl.controls['eventDetailName']['status'] = 'DISABLED';

    }

    private toggleAllSelection() {
        if (this.selectAll) {
            this.selectedRows.clear();
        } else {
            this.houseEventParticipants.forEach((item, index) => {
                this.selectedRows.add(index);
            })
        }
        this.selectAll = !this.selectAll;
    }

    private toggleRowSelection(index: number) {
        if (this.selectedRows.has(index)) {
            this.selectedRows.delete(index);
        } else {
            this.selectedRows.add(index);
        }
        this.selectAll = this.selectedRows.size === this.houseEventParticipants.length;
    }

    private deleteSelected(event) {
        // API integration
        const participants = this.houseEventParticipants.filter((item, index) => this.selectedRows?.has(index))?.map(item => item.participantId);

        let data =
        {
            "eventId": this.activityID,
            "participantIds": participants
        }
        this.showGlobalSpinner = true;
        this.openConfirmDialog("Are you sure you want to delete participant(s)?")
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.houseEventService.deleteHouseEventParticipant(data)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.showGlobalSpinner = false;
                            this.getHouseEventParticipants(this.activityID);
                            this.getAllHouseEvent();
                            this.snackBar.open('Event Participant(s) deleted successfully.', 'OK', {
                                duration: 5000,
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                                panelClass: ['success-snackbar']
                            });
                        },
                            () => {
                                this.showGlobalSpinner = false;
                                this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                                    duration: 8000,
                                    horizontalPosition: this.horizontalPosition,
                                    verticalPosition: this.verticalPosition,
                                    panelClass: ['error-snackbar']
                                });
                            });

                    //this.houseEventParticipants = this.houseEventParticipants.filter((item, index) => !this.selectedRows.has(index));
                    this.selectedRows.clear();
                    this.selectAll = false;
                }
                else {
                    this.showGlobalSpinner = false;
                }
            });
    }

    private showAddParticipants() {
        this.addParticipant = this.addParticipant ? false : true;
        this.addUser.reset();
    }

    isValidCapacity(): boolean {
        return (Number(this.capacity) > this.houseEventParticipants.length) ? true : false;
    }

    private addParticipantToArray() {
        const data =
        {
            "eventId": this.activityID,
            "email": this.inputEmail.trim()
        }
        if (this.addUser.valid) {
            this.showGlobalSpinner = true;
            this.houseEventService.addHouseEventParticipant(data)
                .pipe(take(1))
                .subscribe(() => {
                    this.showGlobalSpinner = false;
                    this.getHouseEventParticipants(this.activityID);
                    this.getAllHouseEvent();
                    this.inputEmail = "";
                    this.addParticipant = false;
                    this.selectedRows.clear();
                    this.selectAll = false;
                    this.snackBar.open('Event Participant Added successfully.', 'OK', {
                        duration: 5000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['success-snackbar']
                    });
                },
                    () => {
                        this.showGlobalSpinner = false;
                        this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                            duration: 8000,
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                            panelClass: ['error-snackbar']
                        });
                    });
        }
    }
    private removeParticipants() {
        this.addParticipant = false;
        this.addUser.reset();
    }


    private validateDeleteEditButons(): void {
        this.disableDeleteEditButons = JSON.parse(JSON.stringify([]));
        const control = this.eventsTable.get('tableRows') as FormArray;
        control.controls.forEach((group: FormGroup) => {
            this.disableDeleteEditButons.push(this.disableEdit(group));
        });
    }

    private disableEdit(group: FormGroup): boolean {
        if (group.get('startDate').value !== null && group.get('startTime').value !== null) {
            let currentEstDateTime = moment()
                .tz("America/New_York")
                .format("YYYY-MM-DD HH:mm:ss");
            let endDateTime = new Date(group.get('endDate').value);
            return endDateTime < new Date(currentEstDateTime);
        }
        else {
            return false;
        }
    }

    private addHouseEvent(fData: IEvent, group?: FormGroup): void {
        this.showGlobalSpinner = true;
        this.houseEventService.addHouseEvent(fData)
            .pipe(take(1))
            .subscribe(() => {
                this.showGlobalSpinner = false;
                this.hasUnSavedChanges = false;
                group.get('isEditable').setValue(false);
                group.get('readMore').setValue(false);
                this.getAllHouseEvent();
                this.snackBar.open('Event added successfully.', 'OK', {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['success-snackbar']
                });
            },
                () => {
                    this.showGlobalSpinner = false;
                    this.snackBar.open('Something went wrong, please try again later, check for possible duplicate record with same event, dates and room.', 'OK', {
                        duration: 8000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['error-snackbar']
                    });
                });
    }

    private updateHouseEvent(id: IEventId, fData: IEvent, group?: FormGroup): void {
        this.showGlobalSpinner = true;
        this.houseEventService.updateHouseEvent(id, fData)
            .pipe(take(1))
            .subscribe(() => {
                this.showGlobalSpinner = false;
                this.hasUnSavedChanges = false;
                group.get('isEditable').setValue(false);
                group.get('readMore').setValue(false);
                this.getAllHouseEvent();
                this.snackBar.open('Event updated successfully.', 'OK', {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['success-snackbar']
                });
            },
                () => {
                    this.showGlobalSpinner = false;
                    this.snackBar.open('Something went wrong, please try again later, check for possible duplicate record with same event, dates and room.', 'OK', {
                        duration: 8000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['error-snackbar']
                    });
                });
    }

    private saveRow(group: FormGroup): void {

        let startTime = moment.utc(group.get('startTime').value, ['h:mm A']).format('HH:mm');
        let endTime = moment.utc(group.get('endTime').value, ['h:mm A']).format('HH:mm');

        let startDateTime = moment(group.get('startDate').value).format('M/D/yyyy') + " " + startTime;
        let endDateTime = moment(group.get('endDate').value).format('M/D/yyyy') + " " + endTime;

        let startDateValue = new Date(startDateTime);
        let endDateValue = new Date(endDateTime);
        let estDateTime = new Date(moment().tz("America/New_York").format('M/D/yyyy HH:mm'));
        let fData, id;
        id = group.get('activityID').value;

        //Invalid DateTime scenario 
        if (startDateValue > endDateValue || startDateValue < estDateTime) {
            this.snackBar.open('Invalid Start-End time input, please change Start-End time input and try again.', 'OK', {
                duration: 8000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['error-snackbar']
            });
        }

        else if (id !== null && this.isCopyRow !== true) {
            fData =
            {
                "activityID": group.get('activityID').value,
                "eventDetailName": group.get('eventDetailName')?.value?.trim(),
                "eventDetailDescription": group.get('eventDetailDescription')?.value?.trim(),
                "capacity": group.get('capacity').value,
                "eventType": group.get('eventType').value,
                "startDate": startDateTime,
                "endDate": endDateTime,
                "functionRoomName": group.get('functionRoomName')?.value?.trim(),
                "status": group.get('status').value,
                "registered": group.get('registered').value,
            };
            this.updateHouseEvent(id, fData, group);
        }
        else {

            fData =
            {
                "eventDetailName": group.get('eventDetailName')?.value?.trim(),
                "eventDetailDescription": group.get('eventDetailDescription')?.value?.trim(),
                "capacity": group.get('capacity').value,
                "eventType": group.get('eventType').value,
                "startDate": startDateTime,
                "endDate": endDateTime,
                "functionRoomName": group.get('functionRoomName')?.value?.trim(),
                "status": group.get('status').value,
                "registered": group.get('registered').value,
            };
            this.addHouseEvent(fData, group);
        }

    }

    private get getFormControls() {
        const control = this.eventsTable.get('tableRows') as FormArray;
        return control;
    }

    private submitForm(): void {
        const control = this.eventsTable.get('tableRows') as FormArray;
        this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    }

    private openConfirmDialog(body: string): Observable<undefined> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "",
                body: body,
                buttonTextNo: "Cancel",
                buttonTextYes: "Yes, delete it!"
            },
            panelClass: 'notification-submit-dialog'
        }).afterClosed();
    }

    uploadFile(type) {
        this.invalidEvents = [];
        this.invalidParticipants = [];
        this.showGlobalSpinner = true;
        type === "showUploadFile" ? this.houseEventService.fileUploadHouseEvent(this.file)
            .pipe(take(1))
            .subscribe((invalidEvents: UploadHouseEventsResponse[]) => {
                this.invalidEvents = invalidEvents;
                this.getAllHouseEvent();
                this.showGlobalSpinner = false;
                if (invalidEvents?.length === 0) {
                    this.snackBar.open('File uploaded successfully.', 'OK', {
                        duration: 5000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['success-snackbar']
                    });
                    this.toggleuploadFileDiv(type);
                } else {
                    this.resetFileNameAndError(type);
                    this.resetFileName();
                }

            },
                () => {
                    this.showGlobalSpinner = false;
                    this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                        duration: 8000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['error-snackbar']
                    });
                }) : this.houseEventService.fileUploadEventParticipants(this.file, this.activityID)
                    .pipe(
                        take(1),
                        expand((res: any) => {
                            const taskId = res.id ?? res;

                            if (!taskId) {
                                // Stop further polling if taskId is missing
                                return throwError(new Error("Task ID is undefined"));

                            }

                            // console.log("Polling for task status with task ID:", taskId);

                            if (res?.status === 'Completed') {
                                return EMPTY;
                            }

                            return this.houseEventService.taskProgress(taskId).pipe(
                                delay(3000), // Wait for 3 seconds before the next check
                                catchError((error) => {
                                    console.error("Error while polling task progress", error);
                                    return throwError(error); // Stop polling on error
                                })
                            );
                        }),
                        takeWhile((res: any) => res?.status !== 'Completed', true),
                        map((res: any) => res),
                        catchError((error) => {
                            //this.showGlobalSpinner = false;
                            return throwError(error);
                        })
                    )
                    .subscribe(
                        (completedTaskResponse) => {
                            if (completedTaskResponse.status == 'Completed') {
                                this.invalidParticipants = completedTaskResponse?.payload || [];
                                this.getHouseEventParticipants(this.activityID);
                                this.showGlobalSpinner = false;
                                this.getAllHouseEvent();
                                if (this.invalidParticipants?.length === 0) {
                                    this.snackBar.open('File uploaded successfully.', 'OK', {
                                        duration: 5000,
                                        horizontalPosition: this.horizontalPosition,
                                        verticalPosition: this.verticalPosition,
                                        panelClass: ['success-snackbar']
                                    });
                                    this.toggleuploadFileDiv(type);
                                } else {
                                    this.resetFileNameAndError(type);
                                    this.resetFileName();
                                }
                            }
                        },
                        () => {
                            this.showGlobalSpinner = false;
                            this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                                duration: 8000,
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                                panelClass: ['error-snackbar']
                            });
                        })
    }

    toggleuploadFileDiv(type) {
        if (type === "hideUploadFile") {
            this.showUploadFile = false;
            this.resetFileName();
        }
        else {
            type === "showUploadFile" ? this.invalidEvents = [] : this.invalidParticipants = [];
            this[type] = !this[type];
            this.resetFileNameAndError(type);
        }
    }

    private resetFileNameAndError(type): void {
        this.fileError = false;
        this.fileNameMissMatch = false;
        this.disableUploadCta = true;
        if (type === "showUploadFile" ? !this.showUploadFile : !this.showEventAttUpload) {
            this.resetFileName();
        }
    }

    private resetFileName(): void {
        this.fileName = 'Choose File';
        this.file = null;
        this.fileError = false;
        if (this.fileEvent && this.fileEvent?.target) {
            this.fileEvent.target.value = '';
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public canDeactivate(nextState: string): boolean | Observable<boolean> | Promise<boolean> {
        if (!this.hasUnSavedChanges && this.fileName === 'Choose File') {
            return true;
        }
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "",
                body: 'Are you sure you want to exit and lose changes?',
                buttonTextNo: "Keep Editing",
                buttonTextYes: "Exit"
            },
            panelClass: 'notification-submit-dialog'
        }).afterClosed().pipe(map(choice => {
            if (choice) {
                return true;
            }
            else if (!choice && nextState === '/admin/programs-external') {
                this.navMenuService.isFacilitatorView();
                this.navMenuService.disableDropdownMenu$.next('admin');
            }
            return false;
        }));
    }

    uploadFileEvt(event: any) {
        this.invalidEvents = [];
        this.fileEvent = event;
        this.fileError = false;
        this.fileNameMissMatch = false;
        const reader = new FileReader();
        if (event?.target?.files?.length) {
            reader?.readAsDataURL(event?.target?.files[0]);
            reader.onload = () => {
                this.fileName = event.target.files[0].name;
                this.file = reader.result;
                let fileExtension = this.fileName.split('.').pop().toString().toLowerCase();
                this.fileError = (!(fileExtension === this.allowedFile[0] || fileExtension === this.allowedFile[1]));
                this.fileNameMissMatch = this.isFileNameInValid();
                const filesize = parseFloat(((event?.target?.files[0]?.size / 1024) / 1024).toFixed(4)); // MB
                this.disableUploadCta = this.fileError || this.fileNameMissMatch || filesize > 50;
            };
        }
    }

    private isFileNameInValid(): boolean {
        this.invalidFileMessage = 'File name';
        const hasHouseEvent = !this.fileName.startsWith('HouseEvent');
        const hasSpace = this.fileName.includes(' ');
        const hasPeriod = this.fileName.split('.')?.length > 2;
        this.invalidFileMessage = hasHouseEvent ? `${this.invalidFileMessage} must start with HouseEvent` : this.invalidFileMessage;
        this.invalidFileMessage = (hasHouseEvent && (hasSpace || hasPeriod)) ? `${this.invalidFileMessage},` : this.invalidFileMessage;
        this.invalidFileMessage = hasSpace ? `${this.invalidFileMessage} should not contain space` : this.invalidFileMessage;
        this.invalidFileMessage = hasPeriod ? hasSpace ? `${this.invalidFileMessage} and period.` : `${this.invalidFileMessage} should not contain period` : this.invalidFileMessage;
        return hasHouseEvent || hasSpace || hasPeriod;
    }

    openDialog(group: any) {
        this.activityID = group.get('activityID').value;
        this.capacity = group.get('capacity').value;
        this.pastEvent = this.disableEdit(group);
        this.selectedRows.clear();
        this.selectAll = false;
        this.showGlobalSpinner = true;
        this.overlayData = {
            "activityID": group.get('activityID').value,
            "eventDetailName": group.get('eventDetailName').value,
            "capacity": group.get('capacity').value,
            "startDate": this.dialogDateFormat(group.get('startDate').value, false),
            "endDate": this.dialogDateFormat(group.get('endDate').value, true),
            "startTime": this.dialogTimeFormat(group.get('startDate').value, false),
            "endTime": this.dialogTimeFormat(group.get('endDate').value, true),
            "functionRoomName": group.get('functionRoomName').value,
            "status": group.get('status').value === 1 ? 'Active' : 'Cancelled',
            "currentStatus": this.houseEventStatus(group.get('startDate').value, group.get('endDate').value),
            "registered": group.get('registered').value,
        }
        //getParticipants
        this.getHouseEventParticipants(this.activityID);
    }

    closeDialog() {
        this.showParticipantList = false;
        this.removeParticipants();
        this.showEventAttUpload = false;
        this.addUser.reset();
        this.resetFileName();
        this.getAllHouseEvent();
    }

    closeDialogConfirm() {
        this.inputEmail?.trim() || this.addParticipant
            || this.showEventAttUpload
            ?
            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: "",
                    body: 'Are you sure you want to exit and lose changes?',
                    buttonTextNo: "Keep Editing",
                    buttonTextYes: "Exit"
                },
                panelClass: 'notification-submit-dialog'
            }).afterClosed()
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.closeDialog();
                    }
                })
            : this.closeDialog();
    }

    private getHouseEventParticipants(activityID) {
        this.houseEventService.getHouseEventParticipants(activityID).pipe(
            takeUntil(this.destroy$),
            map((data: IHouseEventParticipants[]) => data))
            .subscribe((response: IHouseEventParticipants[]) => {
                this.showGlobalSpinner = false;
                this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this.houseEventParticipants = response;
                this.showParticipantList = true;
                this.overlayDetails = this.overlayData;
            },
                () => {
                    this.showGlobalSpinner = false;
                    this.houseEventParticipants = [];
                    this.showParticipantList = true;
                    this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                });
    }

    private dialogDateFormat(startDate: string, day: boolean): String {
        const date: Date = new Date(startDate);
        let dateWithYear: string;
        let dayOfTheWeek: string;

        function getDayOfWeek(date: Date) {
            const dayOfWeek = new Date(date).getDay();
            return isNaN(dayOfWeek) ? null :
                ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
        }

        function getMonthOfDate(date) {
            const monthOfDate = date.getMonth();
            return isNaN(monthOfDate) ? null : ["Jan", "Feb", "Mar", "Apr", "May", "June",
                "July", "Aug", "Sep", "Oct", "Nov", "Dec"][monthOfDate];
        }

        if (day === true) {
            dayOfTheWeek = getDayOfWeek(date);
            return dayOfTheWeek
        }
        else {
            dateWithYear = getMonthOfDate(date) + " " + date.getDate() + ", " + date.getFullYear();
            return dateWithYear;
        }
    }

    private dialogTimeFormat(dateTime: string, endDate: boolean): String {
        const date = new Date(dateTime);

        const formatDateTime = (date) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ap = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes.toString().padStart(2, '0');
            let mergeTime = hours + ':' + minutes + ' ' + ap;
            return mergeTime;
        }

        return formatDateTime(date);
    }

    sortOverlay(property: string): void {
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'name':
                this.isOVNameAsc = !this.isOVNameAsc;
                break;
            case 'email':
                this.isOVEmailAsc = !this.isOVEmailAsc;
                break;
        }

        this.houseEventParticipants.sort((a, b) => {
            let aCompare;
            let bCompare;
            aCompare = a[property].toLowerCase();
            bCompare = b[property].toLowerCase();
            let value = aCompare.localeCompare(bCompare, undefined, { numeric: true, sensitivity: 'base' });
            return (value === -1 ? -1 : (value === 1 ? 1 : 0)) * this.direction;
        });

    }

    private houseEventStatus(startDateTime: number | Date, endDateTime: number | Date): String {

        const currentDate: Date = new Date();

        if (currentDate > startDateTime && currentDate < endDateTime) {
            return "IN SESSION"
        } else if (currentDate < startDateTime) {
            return "UPCOMING"
        } else if (currentDate > endDateTime) {
            return "EXPIRED"
        } else {
            return "CANCELLED"
        }
    }

}