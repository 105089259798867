import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker-input.component.html',
    styleUrls: []
})
export class TimePickerInputComponent implements OnChanges {
    hours: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    minutes: number[];
    defaultMinutes: number[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    @Input() show: boolean;
    @Input() dateTime: string;
    @Input() minutesArr: number[];

    @Output() cancel = new EventEmitter();
    @Output() update = new EventEmitter();

    preTime: any;
    hour: number = 12;
    minute: number = 0;
    ampm: string = "AM";
    ampmOptions: string[] = ["AM", "PM"];


    constructor() { }

    ngOnChanges() {
        if (this.dateTime) {
            if (!this.preTime) {
                this.preTime = this.dateTime;
            }
            let convertedDateTime = moment.utc(this.dateTime, 'hh:mm').format('hh:mm A');
            let fTime = convertedDateTime.split(' ');
            let timeArr = fTime[0].split(':');
            this.hour = +timeArr[0];
            this.minute = +timeArr[1];
            this.ampm = fTime[1];
        }

        if (this.minutesArr) {
            this.minutes = this.minutesArr;
        } else {
            this.minutes = this.defaultMinutes;
        }
    }

    
validateHour() {
    if (this.hour < 1) {
        this.hour = 1;
    } else if (this.hour > 12) {
        this.hour = 12;
    }
}
validateMinute() {
    if (this.minute < 0) {
        this.minute = 0;
    } else if (this.minute > 59) {
        this.minute = 59;
    }
}
validateAmPm() {
    const ampmValue = this.ampm.trim().toUpperCase();
    if (ampmValue === "A") {
        this.ampm = "AM";
    } else if (ampmValue === "P") {
        this.ampm = "PM";
    } else if (ampmValue !== "AM" && ampmValue !== "PM") {
        // Reset to default if invalid
        this.ampm = "AM";
    }
}
setAmPm(value: string) {
    this.ampm = value;
}

// Toggle AM/PM using keyboard arrows
toggleAmPmKey(event: KeyboardEvent) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        this.ampm = this.ampm === "AM" ? "PM" : "AM";
        event.preventDefault(); // Prevent cursor movement
    }
}

    cancelClick() {
        this.cancel.emit('');
    }

    updateClick() {
        let timeObj = `${this.hour}:${this.minute} ${this.ampm}`;
        this.update.emit({
            value: moment.utc(timeObj, ['h:mm A']).format('HH:mm'),
            preTime: this.preTime
        });
    }
}
