import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ProgramService } from "../../../../../../services/aws/program/program.service";
import { Observable, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { IMissedInfo, IDeletedInfo, IMissedPrePostInfo } from "../../../../../../models/ITimeMissedInfo";
import { IParticipants, IFacilitators } from "../../../../../../models/IClassRoster";
import * as moment from 'moment';
import { ClassRosterService } from "src/app/services/aws/class/class-roster.service";
import { AuthenticationService } from "../../../../../../services/authentication/authentication.service";
import { IConfirmation } from "../../../../../../models/IConfirmation";
import { ReportService } from "../../../../../../services/aws/report/report.service";
import { Router } from '@angular/router';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { TimeDiff } from 'src/app/widgets/pipes/timeDiff.pipe';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { ClassDetailComponent } from "../classdetail.component";

@Component(
    {
        selector: "class-roster",
        templateUrl: "./class-roster.component.html",
        styleUrls: [],
        providers: [TimeDiff]
    }
)
export class ClassRosterComponent implements OnInit, OnDestroy {
    @Input() roster: any;
    @Input() programId: any;
    @Input() classId: any;
    @Input() reportName: any;
    @Input() classDate: any;
    @Input() classEndDate: any;
    @Input() facilitatorAttendance: boolean;
    @Output() editedIndexEvent = new EventEmitter<any>();
    @Output() isAnyFormOpen = new EventEmitter<any>();

    protected destroy$ = new Subject();
    public facilitatorsErrorMessage: IErrorMessage = {};
    public participantsErrorMessage: IErrorMessage = {};
    facilitators: IFacilitators[];
    _facilitators: IFacilitators[];
    participants: IParticipants[];
    _participants: IParticipants[];
    show: boolean = true;
    showLoading = false;
    isDesc: boolean = false;
    column: string = 'fullName';
    direction: number;
    isNameAsc: boolean = false;
    isEmailAsc: boolean = false;
    isfacilitationAsc: boolean = false;
    isParticipationAsc: boolean = false;
    isStatusAsc: boolean = false;
    isCheckInAsc: boolean = false;
    isCheckInByAsc: boolean = false;
    isParticipantNameAsc: boolean = false;
    isParticipantEmailAsc: boolean = false;
    isParticipantTimeMissedAsc: boolean = false;
    selectedIndex: number;
    participantSelectedIndex: number;
    selectedPickerId: number;
    selectedType: string;
    selectedTarget: string;
    missedInfo: IMissedInfo = {} as IMissedInfo;
    arrMissedInfo: IMissedInfo[] = [];
    deletedInfo: IDeletedInfo = {} as IDeletedInfo;
    addedParticipantsInfo: any[] = [] as any[];
    addedFacilitatorsInfo: any[] = [] as any[];
    addedFacilitation: any[] = [] as any[];
    addedParticipation: any[] = [];
    newAddedDetails: IMissedInfo = {} as IMissedInfo;
    isAdded = false;
    isDeleted = false;
    listText = '';
    getNewUpdatedMissedTime: any;
    addNewReasonItems: string;
    isSuccess = false;
    isShowSave = false;
    protected updatedDetailCache: IMissedPrePostInfo[] = [];
    updatedDetails: IMissedPrePostInfo = {} as IMissedPrePostInfo;
    selectedBox;
    selectedParticipantBox;
    reasonBox;
    saveReason;
    reasonItems: string;
    items: string;
    successMsg;
    cancelBtn;
    undoBtn;
    keepEditing;
    search: any;
    isParticipantDesc: boolean = false;
    participantColumn: string = 'fullName';
    participantDirection: number;
    emaildetail$: any;
    email: any;
    facilitatorIndex: any;
    facilitationSelectedIndex: number;
    participatiopnSelectedIndex: number;
    enableCheckbox = true;
    isConfirmed = false;
    isFacilitatorOver: string;
    doConfirm = false;
    public confirmationDataErrorMessage: IErrorMessage = {};
    confirmationData;
    personDetail$: Observable<any>;
    showReport = false;
    commentBoxType: string;
    cancelModel = false;
    cancelType: string;
    showReason: boolean = false;
    classStatus: number;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    editStatusMap: { [key: number]: boolean } = {};

    constructor(protected http: HttpClientModule, protected programService: ProgramService,
        protected classRosterService: ClassRosterService,
        protected auth: AuthenticationService,
        protected reportService: ReportService,
        protected timeDiff: TimeDiff,
        protected router: Router,
        protected snackBar: MatSnackBar,
        protected  classDetailComponent: ClassDetailComponent) 
        {
        this.participantDirection = 1;
        this.direction = 1;
        // this.addedFacilitation.checkInTime = 'Add Time';
        // this.addedFacilitation.checkOutTime = 'Add Time';
        // this.addedParticipation.checkInTime = 'Add Time';
        // this.addedParticipation.checkOutTime = 'Add Time';
        this.emaildetail$ = this.auth.getUserEmail();
    }


    ngOnInit() {
        this.emaildetail$.pipe(take(1)).subscribe(x => {
            this.email = x;
            this.getConfirmation(this.programId, this.classId);
        });

        this.getRosterFacilitators(true);

        this.getRosterParticipants();


        if (moment(this.classDate).format('YYYY-MM-DD HH:mm') <= moment(new Date()).format('YYYY-MM-DD HH:mm')) {
            this.enableCheckbox = false;
        }
    }

    expandCollapse(type, values, index?) {
        if (type === 'facilitator') {
            values.isEdit = !values.isEdit;
        } else {
            values.isEdit = !values.isEdit;
        }
        if (values.isEdit) {
            this.editStatusMap[index] = true;
        } else {
            delete this.editStatusMap[index]; // Clean up when closed
        }
        const isAnyOpen = Object.values(this.editStatusMap).some(status => status === true);
        this.isAnyFormOpen.emit(isAnyOpen);
        let selected = index ? index : null;
        this.editedIndexEvent.emit(selected);
        this.resetFields();
    }

    expandAll(type) {
        if (type === 'facilitator') {
            this.facilitators.forEach((element) => {
                element.isEdit = true;
            });
        } else {
            this.participants.forEach((element) => {
                element.isEdit = true;
            });
        }
        this.isAnyFormOpen.emit(true);
        this.resetFields();
    }

    collapseAll(type) {
        if (type === 'facilitator') {
            this.facilitators.forEach((element) => {
                element.isEdit = false;
            });
        } else {
            this.participants.forEach((element) => {
                element.isEdit = false;
            });
        }
        this.isAnyFormOpen.emit(false);
        this.resetFields();
    }

    protected getNotifications() {
        if (this.classStatus == 1) {
            this.isFacilitatorOver = 'disable';
        } else if (this.classStatus == 2) {
            this.isFacilitatorOver = 'allow';
        } else if (this.classStatus == 3) {
            this.isFacilitatorOver = 'over';
        } else if (this.classStatus == 4) {
            this.isFacilitatorOver = 'isConfirmed';
        }
    }

    toggleRow(index, type) {
        if (type == 'participants') {
            this.selectedIndex = null;
            this.participantSelectedIndex = this.participantSelectedIndex == index ? null : index;
        } else {
            this.selectedIndex = this.selectedIndex == index ? null : index;
            this.participantSelectedIndex = null;
        }
        let selected = (this.participantSelectedIndex == index || this.selectedIndex == index) ? index : null;
        this.editedIndexEvent.emit(selected);
        // this.resetFields();
    }



    protected resetFields() {
        this.newAddedDetails = {} as IMissedInfo;
        this.newAddedDetails.checkInTime = undefined;
        this.newAddedDetails.checkOutTime = undefined;
        this.isAdded = false;
        this.isShowSave = false;
        this.addNewReasonItems = undefined;
        this.prepareAddParticipantsInfo(<IParticipants[]>this.participants);
        this.prepareAddFacilitatorsInfo(this.facilitators);
        // this.addedParticipantsInfo.checkInTime = 'Add Time';
        // this.addedParticipantsInfo.checkOutTime = 'Add Time';
        // this.addedFacilitation.checkInTime = 'Add Time';
        // this.addedFacilitation.checkOutTime = 'Add Time';
        // this.addedParticipation.checkInTime = 'Add Time';
        // this.addedParticipation.checkOutTime = 'Add Time';
        this.updatedDetailCache.splice(0, this.updatedDetailCache.length);
    }

    toggleComment(i, type) {
        if (type == 'participants') {
            this.selectedParticipantBox = i;
            this.commentBoxType = 'participants';
        } else {
            this.selectedBox = i;
            this.commentBoxType = 'facilitators';
        }
    }

    closeComment(i, type) {
        if (type == 'facilitators') {
            this.selectedBox = null;
        } else this.selectedParticipantBox = null;
    }

    toggleReason(i) {
        this.reasonBox = i;
        this.saveReason = i;
        this.cancelBtn = i;
        this.undoBtn = i;
        this.successMsg = null;
        this.keepEditing = null;
    }

    closeReason() {
        this.reasonBox = null;
        this.saveReason = null;
        this.cancelBtn = null;
        this.undoBtn = null;
    }

    SaveReasonBtn(i) {
        this.reasonBox = null;
        this.saveReason = null;
        this.cancelBtn = null;
        this.undoBtn = null;
        this.successMsg = i;
    }

    closeSuccess() {
        this.reasonBox = null;
        this.saveReason = null;
        this.cancelBtn = null;
        this.undoBtn = null;
        this.successMsg = null;
    }

    toggleCancel(i) {
        this.keepEditing = i;
    }


    toggleCancelClose() {
        this.keepEditing = null;
    }

    sort(property, type) {
        if (type == 'facilitator') {
            this.isDesc = !this.isDesc;
            this.column = property;
            this.direction = this.isDesc ? 1 : -1;
            switch (property) {
                case 'lastName':
                    this.isNameAsc = !this.isNameAsc;
                    break;
                case 'fullName':
                    this.isNameAsc = !this.isNameAsc;
                    break;
                case 'email':
                    this.isEmailAsc = !this.isEmailAsc;
                    break;
            }
        } else {
            this.isParticipantDesc = !this.isParticipantDesc;
            this.participantColumn = property;
            this.participantDirection = this.isParticipantDesc ? 1 : -1;
            switch (property) {
                case 'lastName':
                    this.isParticipantNameAsc = !this.isParticipantNameAsc;
                    break;
                case 'fullName':
                    this.isNameAsc = !this.isNameAsc;
                    break;
                case 'email':
                    this.isParticipantEmailAsc = !this.isParticipantEmailAsc;
                    break;
                case 'timeMissed':
                    this.isParticipantTimeMissedAsc = !this.isParticipantTimeMissedAsc;
                    break;
                case 'status':
                    this.isStatusAsc = !this.isStatusAsc;
                    break;
                case 'checkIn':
                    this.isCheckInAsc = !this.isCheckInAsc;
                    break;
                case 'checkInBy':
                    this.isCheckInByAsc = !this.isCheckInByAsc;
                    break;
            }
        }
    }

    onFilter = () => {
        if (this.search) {
            let searchValue = this.search;
            this.roster = this.roster.detailList.participants.filter(item => (item.name.toLowerCase().includes(searchValue) || item.email.toLowerCase().includes(searchValue)));
        }
    };

    checkInStatus($event, participantId, data) {
        let participantCheckStatus = {
            participantId: participantId,
            checkIn: {
                status: $event.source ? $event.source.checked ? true : false : false,
                updatedBy: this.facilitatorIndex >= 0 ? "Facilitator" : 'Admin',
                createdBy: this.email
            }
        };
        participantCheckStatus.checkIn["recordId"] = data?.checkIn?.recordId;
        this.classRosterService.edit(this.programId, this.classId, ($event.source.id) ? "update" : "insert", "checkInStatus");
        this.classRosterService.update(participantCheckStatus);
        this.classRosterService.save().pipe(take(1)).subscribe(
            () => {
                this.getRosterFacilitators();
                this.getRosterParticipants();
                this.classDetailComponent.getDetail()
            });
    }

    handleFacilitationResponse($event, response, participantId, target) {
        const facilitationResponse: any = {
            participantId,
            facilitationResponse: {
                updatedBy: this.facilitatorIndex >= 0 ? "Facilitator" : 'Admin',
                createdBy: this.email
            }
        };
        if (target === "facilitation") {
            facilitationResponse.facilitationResponse.facilitationResponse = $event?.target?.value;
            facilitationResponse.facilitationResponse.participationResponse = response?.participationResponse;

        } else {
            facilitationResponse.facilitationResponse.participationResponse = $event?.target.value;
            facilitationResponse.facilitationResponse.facilitationResponse = response?.facilitationResponse;
        }
        if (response && response.id) {
            facilitationResponse.facilitationResponse["id"] = response.id;
        }
        this.classRosterService.edit(this.programId, this.classId, (response && response.id) ? "update" : "insert", "facilitationResponse");
        this.classRosterService.update(facilitationResponse);
        this.classRosterService.save().pipe(take(1)).subscribe(() => {
            this.getRosterFacilitators();
        });
        this.toggleRow(null, 'facilitator');
        this.getRosterFacilitators();
    }

    togglePicker(participantId?, timeMissed = null, type = null, target = null) {
        this.listText = '';
        this.selectedPickerId = (timeMissed) ? timeMissed.id : 0;
        this.selectedType = type;
        this.selectedTarget = target;
        if (this.selectedTarget === 'participant' && participantId) {
            this.participantSelectedIndex = this.participants.findIndex((val: any) => val.participantId === participantId);
            this.addedParticipantsInfo[this.participantSelectedIndex].selectedType = type;
            this.addedParticipantsInfo[this.participantSelectedIndex].selectedTarget = target;
        } else if (participantId) {
            this.selectedIndex = this.facilitators.findIndex((val: any) => val.participantId === participantId);
            this.addedFacilitatorsInfo[this.selectedIndex].selectedType = type;
            this.addedFacilitatorsInfo[this.selectedIndex].selectedTarget = target;
            this.enableFacilitatorSaveCta();
        }
        let copy: any;
        if (type) {
            switch (this.selectedTarget) {
                case "facilitation":
                    let facilitation = (timeMissed) ? timeMissed : {
                        checkInTime: "Add Time",
                        checkOutTime: "Add Time",
                        checkInBy: this.facilitatorIndex >= 0 ? "Facilitator" : 'Admin',
                        createdBy: this.email
                    };
                    copy = {
                        participantId: participantId,
                        facilitationTime: [facilitation]
                    };
                    this.classRosterService.edit(this.programId, this.classId, (timeMissed) ? "update" : "insert", target, copy);
                    this.classRosterService.update(copy);
                    break;
                case "participation":
                    let participation = (timeMissed) ? timeMissed : {
                        checkInTime: "Add Time",
                        checkOutTime: "Add Time",
                        checkInBy: this.facilitatorIndex >= 0 ? "Facilitator" : 'Admin',
                        createdBy: this.email
                    };
                    copy = {
                        participantId: participantId,
                        participationTime: [participation]
                    };
                    this.classRosterService.edit(this.programId, this.classId, (timeMissed) ? "update" : "insert", target, copy);
                    this.classRosterService.update(copy);
                    break;
                default:
                    let miss = (timeMissed) ? timeMissed : {
                        classDate: moment(this.classDate).format('YYYY-MM-DD'),
                        checkInTime: "Add Time",
                        checkOutTime: "Add Time",
                        checkInBy: this.facilitatorIndex >= 0 ? "Facilitator" : 'Admin',
                        createdBy: this.email
                    };
                    copy = {
                        participantId: participantId,
                        totalMissed: [miss]
                    };
                    this.classRosterService.edit(this.programId, this.classId, (timeMissed) ? "update" : "insert", target, copy);
                    this.classRosterService.update(copy);
            }
        }
    }

    protected enableFacilitatorSaveCta(): void {
        let facilitationTimeEnableCta = false;
        let participationTimeEnableCta = false;
        if (this.addedFacilitatorsInfo[this.selectedIndex]?.facilitation) {
            const facilitationTime = this.addedFacilitatorsInfo[this.selectedIndex]?.facilitation?.facilitationTime[0];
            facilitationTimeEnableCta = facilitationTime?.checkInTime !== facilitationTime?.checkOutTime;
        } else {
            facilitationTimeEnableCta = this.addedFacilitatorsInfo[this.selectedIndex]?.checkInTime !== this.addedFacilitatorsInfo[this.selectedIndex]?.checkOutTime;
        }
        if (this.addedFacilitatorsInfo[this.selectedIndex]?.participation) {
            const participationTime = this.addedFacilitatorsInfo[this.selectedIndex]?.participation?.participationTime[0];
            participationTimeEnableCta = participationTime?.checkInTime !== participationTime?.checkOutTime;
        } else {
            participationTimeEnableCta = this.addedFacilitatorsInfo[this.selectedIndex]?.checkInTime !== this.addedFacilitatorsInfo[this.selectedIndex]?.checkOutTime;
        }
        this.addedFacilitatorsInfo[this.selectedIndex].enableSaveCta = facilitationTimeEnableCta || participationTimeEnableCta;
    }

    update($event) {
        this.isShowSave = true;
        switch (this.selectedTarget) {
            case "facilitation":
                this.showReason = false;
                let facilitation = this.classRosterService.update();
                if (facilitation?.facilitationTime[0].id) {
                    if (!this.updatedDetails.preCheckInTime) this.updatedDetails.preCheckInTime = this.formatTime(facilitation.facilitationTime[0].checkInTime);
                    if (!this.updatedDetails.preCheckOutTime) this.updatedDetails.preCheckOutTime = this.formatTime(facilitation.facilitationTime[0].checkOutTime);
                    if (this.selectedType == "checkIn") {
                        this.updatedDetails.id = facilitation.facilitationTime[0].id;
                        this.updatedDetails.checkInTime = this.formatTime($event.value);
                        this.updatedDetails.checkOutTime = this.formatTime(facilitation.facilitationTime[0].checkOutTime);
                        facilitation.facilitationTime[0].checkInTime = this.formatTime($event.value);
                        facilitation.facilitationTime[0].checkOutTime = this.formatTime(facilitation.facilitationTime[0].checkOutTime);
                    } else if (this.selectedType == "checkOut") {
                        this.updatedDetails.id = facilitation.facilitationTime[0].id;
                        this.updatedDetails.checkOutTime = this.formatTime($event.value);
                        this.updatedDetails.checkInTime = this.formatTime(facilitation.facilitationTime[0].checkInTime);
                        facilitation.facilitationTime[0].checkOutTime = this.formatTime($event.value);
                        facilitation.facilitationTime[0].checkInTime = this.formatTime(facilitation.facilitationTime[0].checkInTime);
                    }
                    facilitation.facilitationTime[0].createdBy = this.email;
                    this.updateSaveBarEditList();
                } else {
                    this.isAdded = true;
                    if (this.selectedType == "addCheckIn") {
                        this.newAddedDetails.checkInTime = this.formatTime($event.value);
                        this.addedFacilitation[this.selectedIndex].checkInTime = this.formatTime($event.value);
                        facilitation.facilitationTime[0].checkInTime = this.formatTime($event.value);
                        facilitation.facilitationTime[0].checkOutTime = this.newAddedDetails.checkInTime;
                    } else if (this.selectedType == "addCheckOut") {
                        this.newAddedDetails.checkOutTime = this.formatTime($event.value);
                        this.addedFacilitation[this.selectedIndex].checkOutTime = this.formatTime($event.value);
                        facilitation.facilitationTime[0].checkOutTime = this.formatTime($event.value);
                        facilitation.facilitationTime[0].checkInTime = this.newAddedDetails.checkInTime;
                    }
                }
                this.classRosterService.update(facilitation);
                this.addedFacilitatorsInfo[this.selectedIndex].facilitation = facilitation;
                this.enableFacilitatorSaveCta();
                // this.addedFacilitatorsInfo[this.selectedIndex].enableSaveCta = facilitation?.facilitationTime[0].checkInTime !== facilitation?.facilitationTime[0].checkOutTime;
                break;
            case "participation":
                this.showReason = false;
                let participation = this.classRosterService.update();
                if (participation.participationTime[0].id) {
                    if (!this.updatedDetails.preCheckInTime) this.updatedDetails.preCheckInTime = this.formatTime(participation.participationTime[0].checkInTime);
                    if (!this.updatedDetails.preCheckOutTime) this.updatedDetails.preCheckOutTime = this.formatTime(participation.participationTime[0].checkOutTime);
                    if (this.selectedType == "checkIn") {
                        this.updatedDetails.id = participation.participationTime[0].id;
                        this.updatedDetails.checkInTime = this.formatTime($event.value);
                        this.updatedDetails.checkOutTime = this.formatTime(participation.participationTime[0].checkOutTime);
                        participation.participationTime[0].checkInTime = this.formatTime($event.value);
                        participation.participationTime[0].checkOutTime = this.formatTime(participation.participationTime[0].checkOutTime);
                    } else if (this.selectedType == "checkOut") {
                        this.updatedDetails.id = participation.participationTime[0].id;
                        this.updatedDetails.checkOutTime = this.formatTime($event.value);
                        this.updatedDetails.checkInTime = this.formatTime(participation.participationTime[0].checkInTime);
                        participation.participationTime[0].checkOutTime = this.formatTime($event.value);
                        participation.participationTime[0].checkInTime = this.formatTime(participation.participationTime[0].checkInTime);
                    }
                    participation.participationTime[0].createdBy = this.email;
                    this.updateSaveBarEditList();
                } else {
                    this.isAdded = true;
                    if (this.selectedType == "addCheckIn") {
                        this.newAddedDetails.checkInTime = this.formatTime($event.value);
                        this.addedParticipation[this.selectedIndex].checkInTime = this.formatTime($event.value);
                        participation.participationTime[0].checkInTime = this.formatTime($event.value);
                        participation.participationTime[0].checkOutTime = this.newAddedDetails.checkInTime;
                    } else if (this.selectedType == "addCheckOut") {
                        this.newAddedDetails.checkOutTime = this.formatTime($event.value);
                        this.addedParticipation[this.selectedIndex].checkOutTime = this.formatTime($event.value);
                        participation.participationTime[0].checkOutTime = this.formatTime($event.value);
                        participation.participationTime[0].checkInTime = this.newAddedDetails.checkInTime;
                    }
                }
                this.classRosterService.update(participation);
                this.addedFacilitatorsInfo[this.selectedIndex].participation = participation;
                this.enableFacilitatorSaveCta();
                // this.addedFacilitatorsInfo[this.selectedIndex].enableSaveCta = participation?.participationTime[0].checkInTime !== participation?.participationTime[0].checkOutTime;
                break;
            default:
                this.showReason = true;
                let miss = this.classRosterService.update();
                if (miss.totalMissed[0].id) {
                    if (!this.updatedDetails.preCheckInTime) this.updatedDetails.preCheckInTime = this.formatTime(miss.totalMissed[0].checkInTime);
                    if (!this.updatedDetails.preCheckOutTime) this.updatedDetails.preCheckOutTime = this.formatTime(miss.totalMissed[0].checkOutTime);
                    if (this.selectedType == "checkIn") {

                        this.updatedDetails.id = miss.totalMissed[0].id;
                        this.updatedDetails.checkInTime = this.formatTime($event.value);
                        this.updatedDetails.checkOutTime = this.formatTime(miss.totalMissed[0].checkOutTime);
                        miss.totalMissed[0].checkInTime = this.formatTime($event.value);
                        miss.totalMissed[0].checkOutTime = this.formatTime(miss.totalMissed[0].checkOutTime);


                    } else if (this.selectedType == "checkOut") {
                        this.updatedDetails.id = miss.totalMissed[0].id;
                        this.updatedDetails.checkOutTime = this.formatTime($event.value);
                        this.updatedDetails.checkInTime = this.formatTime(miss.totalMissed[0].checkInTime);
                        miss.totalMissed[0].checkOutTime = this.formatTime($event.value);
                        miss.totalMissed[0].checkInTime = this.formatTime(miss.totalMissed[0].checkInTime);

                    }
                    miss.totalMissed[0].createdBy = this.email;
                    this.updateSaveBarEditList();
                    if (this.selectedTarget === 'facilitator') {
                        this.addedFacilitatorsInfo[this.selectedIndex].miss = JSON.parse(JSON.stringify(miss));
                    } else {
                        this.addedParticipantsInfo[this.participantSelectedIndex].miss = JSON.parse(JSON.stringify(miss));
                    }
                } else {
                    this.isAdded = true;
                    if (this.selectedType == "addCheckIn") {
                        this.newAddedDetails.checkInTime = this.formatTime($event.value);
                        if (this.selectedTarget === 'facilitator') {
                            this.addedFacilitatorsInfo[this.selectedIndex].checkInTime = this.formatTime($event.value);
                        } else {
                            this.addedParticipantsInfo[this.participantSelectedIndex].checkInTime = this.formatTime($event.value);
                        }
                        miss.totalMissed[0].checkInTime = this.formatTime($event.value);
                        miss.totalMissed[0].checkOutTime = this.newAddedDetails.checkInTime;
                    } else if (this.selectedType == "addCheckOut") {
                        this.newAddedDetails.checkOutTime = this.formatTime($event.value);
                        if (this.selectedTarget === 'facilitator') {
                            this.addedFacilitatorsInfo[this.selectedIndex].checkOutTime = this.formatTime($event.value);
                        } else {
                            this.addedParticipantsInfo[this.participantSelectedIndex].checkOutTime = this.formatTime($event.value);
                        }
                        miss.totalMissed[0].checkOutTime = this.formatTime($event.value);
                        miss.totalMissed[0].checkInTime = this.newAddedDetails.checkInTime;

                    }
                    const timeDiff = Math.sign(moment(miss.totalMissed[0].checkOutTime, ['HH:mm:ss']).diff(moment(miss.totalMissed[0].checkInTime, ['HH:mm:ss'])));
                    if (timeDiff <= 0) {
                        this.isShowSave = false;
                    }
                    if (this.selectedTarget === 'facilitator') {
                        this.addedFacilitatorsInfo[this.selectedIndex].miss = JSON.parse(JSON.stringify(miss));
                    } else {
                        this.addedParticipantsInfo[this.participantSelectedIndex].miss = JSON.parse(JSON.stringify(miss));
                    }

                    if (miss.totalMissed[0].checkInTime == "Invalid date"
                        || miss.totalMissed[0].checkOutTime == "Invalid date") {

                        this.resetFields();
                        this.snackBar.open('Invalid Time Missed Input.', 'OK', {
                            duration: 8000,
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                            panelClass: ['error-snackbar']
                        });
                    }
                    else {
                        this.classRosterService.update(miss);
                    }
                }
        }

        this.togglePicker();
    }

    delete(pid, time, target, index) {
        switch (target) {
            case "facilitation":
                this.missedInfo.id = time.id;
                this.missedInfo.checkInBy = time.checkInBy;
                this.missedInfo.createdBy = this.email;
                this.arrMissedInfo.push(this.missedInfo);
                this.deletedInfo.participantId = pid;
                this.deletedInfo.facilitationTime = this.arrMissedInfo;
                this.classRosterService.edit(this.programId, this.classId, "delete", "facilitation");
                this.classRosterService.update(this.deletedInfo);
                this.addedFacilitatorsInfo[index].enableSaveCta = true;
                this.facilitationSelectedIndex = index;
                this.setSaveBarValues(time);
                break;
            case "participation":
                this.missedInfo.id = time.id;
                this.missedInfo.checkInBy = time.checkInBy;
                this.missedInfo.createdBy = this.email;
                this.arrMissedInfo.push(this.missedInfo);
                this.deletedInfo.participantId = pid;
                this.deletedInfo.participationTime = this.arrMissedInfo;
                this.classRosterService.edit(this.programId, this.classId, "delete", "participation");
                this.classRosterService.update(this.deletedInfo);
                this.addedFacilitatorsInfo[index].enableSaveCta = true;
                this.participatiopnSelectedIndex = index;
                this.setSaveBarValues(time);
                break;
            default:
                this.missedInfo.id = time.id;
                this.missedInfo.checkInBy = time.checkInBy;
                this.missedInfo.comments = time.comments;
                this.missedInfo.createdBy = this.email;
                this.arrMissedInfo.push(this.missedInfo);
                this.deletedInfo.participantId = pid;
                this.deletedInfo.totalMissed = this.arrMissedInfo;
                this.classRosterService.edit(this.programId, this.classId, "delete", "participant");
                this.classRosterService.update(this.deletedInfo);
                if (target === 'facilitators') {
                    this.selectedIndex = index;
                    this.addedFacilitatorsInfo[index].enableSaveCta = true;
                } else if (target === 'participants') {
                    this.addedParticipantsInfo[index].enableSaveCta = true;
                    this.participantSelectedIndex = index;
                }
                this.setSaveBarValues(time);
        }
        if (target === 'facilitators') {
            this.selectedIndex = index;
            this.addedFacilitatorsInfo[index].enableSaveCta = true;
        } else if (target === 'participants') {
            this.addedParticipantsInfo[index].enableSaveCta = true;
            this.participantSelectedIndex = index;
        }
    }

    confirm() {
        this.doConfirm = true;
    }

    saveConfirm() {
        this.doConfirm = false;
        this.classRosterService
            .postConfirmation(
                {
                    facilitatorId: this.email,
                    confirmed: true,
                    role: 'facilitator'
                } as IConfirmation,
                this.programId, this.classId
            )
            .pipe(take(1))
            .subscribe(() => {
                this.getConfirmation(this.programId, this.classId);
                this.isConfirmed = true;
            },
                () => {
                    this.snackBar.open('Failed to save confirmation of the attendance. Please try again.', 'OK', {
                        duration: 8000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['error-snackbar']
                    });
                });
    }

    cancelConfirm() {
        this.doConfirm = false;
    }

    public updateCommentFacilitator($event): void {
        if ($event?.type === 'facilitator') {
            this.addedFacilitatorsInfo[this.selectedIndex].miss.totalMissed[0].comments = $event.comments;
        } else {
            this.addedParticipantsInfo[this.participantSelectedIndex].miss.totalMissed[0].comments = $event.comments;
        }
    }

    download(type) {
        switch (type) {
            case 'wellbeing':
                this.reportService.getWellbeingCheckReport(this.programId, this.classId, this.reportName + '_Wellbeing');
                break;
            case 'participants':
                this.reportService.geParticipantCheckReport(this.programId, this.classId, this.reportName + '_Attendance');
                break;
        }
    }

    openReport() {
        this.showReport = !this.showReport;
    }

    cancelWidgetConfirm() {
        this.cancelModel = !this.cancelModel;
        this.prepareAddFacilitatorsInfo(this.facilitators);
        this.prepareAddParticipantsInfo(this.participants);
        this.toggleRow(null, this.cancelType);
    }

    keepCancelWidgetEditing(type) {
        this.participants = JSON.parse(JSON.stringify(this._participants));
        this.facilitators = JSON.parse(JSON.stringify(this._facilitators));
        this.cancelType = type;
        this.cancelModel = !this.cancelModel;
    }

    getConfirmation(programId, classId) {
        this.confirmationDataErrorMessage = {};
        this.classRosterService.getConfirmation(programId, classId).pipe(
            takeUntil(this.destroy$),
            map(data => data))
            .subscribe((response: IConfirmation) => {
                this.confirmationDataErrorMessage.text = response ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this.confirmationData = response;
                this.isConfirmed = response.confirmed;
                this.classStatus = response.classStatus;
                this.getNotifications();
            },
                () => {
                    this.confirmationData = [];
                    this.confirmationDataErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                });
    }

    getRosterFacilitators(initialCall?) {
        this.facilitatorsErrorMessage = {};
        this.programService.getRosterFacilitators(this.programId, this.classId).pipe(
            takeUntil(this.destroy$),
            map(data => data))
            .subscribe((response) => {
                this.facilitatorsErrorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                const currentInfo = JSON.parse(JSON.stringify(this.facilitators || []));
                response.map(x => x.fullName = x?.lastName + ', ' + x?.firstName);
                this.facilitators = response;
                this._facilitators = JSON.parse(JSON.stringify(this.facilitators));
                this.prepareAddFacilitatorsInfo(currentInfo);
                if (initialCall) {
                    this.facilitatorIndex = response.findIndex(y => y?.email?.toLowerCase() === this.email?.toLowerCase());
                }
            },
                () => {
                    this.facilitators = [];
                    this._facilitators = JSON.parse(JSON.stringify(this.facilitators));
                    this.facilitatorsErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                });
    }

    getRosterParticipants() {
        this.participantsErrorMessage = {};
        this.programService.getRosterParticipants(this.programId, this.classId).pipe(
            takeUntil(this.destroy$),
            map(data => data))
            .subscribe((response: IParticipants[]) => {
                this.participantsErrorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                const currentInfo = JSON.parse(JSON.stringify(this.participants || []));
                response.map(x => x.fullName = x?.lastName + ', ' + x?.firstName);
                this.participants = response;
                this._participants = JSON.parse(JSON.stringify(this.participants));
                this.prepareAddParticipantsInfo(currentInfo);
            },
                () => {
                    this.participants = [];
                    this._participants = JSON.parse(JSON.stringify(this.participants));
                    this.participantsErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                });
    }

    protected prepareAddParticipantsInfo(currentInfo: IParticipants[]): void {
        this.addedParticipantsInfo = [];
        this.updatedDetailCache = [];
        this.updatedDetails = <IMissedPrePostInfo>{};
        const checkInOut = {
            checkInTime: "Add Time",
            checkOutTime: 'Add Time'
        };
        this.participants.forEach((participant: IParticipants, index: number) => {
            participant.isEdit = currentInfo[index]?.isEdit || false;
            this.addedParticipantsInfo.push(<IMissedInfo>JSON.parse(JSON.stringify(checkInOut)));
        });
    }

    protected prepareAddFacilitatorsInfo(currentInfo: IFacilitators[]): void {
        this.addedFacilitatorsInfo = [];
        this.addedParticipation = [];
        this.addedFacilitation = [];
        this.updatedDetailCache = [];
        this.updatedDetails = <IMissedPrePostInfo>{};
        const checkInOut = {
            checkInTime: "Add Time",
            checkOutTime: 'Add Time'
        };
        this.facilitators.forEach((facilitator: IFacilitators, index: number) => {
            facilitator.isEdit = currentInfo[index]?.isEdit || false;
            this.addedFacilitatorsInfo.push(<IMissedInfo>JSON.parse(JSON.stringify(checkInOut)));
            this.addedParticipation.push(<IMissedInfo>JSON.parse(JSON.stringify(checkInOut)));
            this.addedFacilitation.push(<IMissedInfo>JSON.parse(JSON.stringify(checkInOut)));
        });
    }

    protected updateSaveBarEditList() {
        this.updatedDetailCache = this.updatedDetailCache.filter(x => x.id != this.updatedDetails.id);
        this.updatedDetailCache.push(this.updatedDetails);
        // this.getRosterParticipants();
    }

    protected formatTime(time) {
        return moment.utc(time, ['h:mm A']).format('HH:mm:ss');
    }

    protected setSaveBarValues(timeMissed) {
        this.isShowSave = true;
        this.isAdded = true;
        this.isDeleted = true;
        this.listText = 'Time Deleted';
        this.newAddedDetails = {} as IMissedInfo;
        this.newAddedDetails.checkInTime = timeMissed.checkInTime;
        this.newAddedDetails.checkOutTime = timeMissed.checkOutTime;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    totalTimeMissed(totalMissed) {
        let total = 0;
        totalMissed?.forEach((element) => {
            total = total + this.timeDiff.transform(element?.checkInTime, element?.checkOutTime);
        });
        return total;
    }
}
