import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgramService } from '../../../../services/aws/program/program.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ISession } from '../../../../models/IProgram';
import { filter, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { Store } from '@ngxs/store';
import { ProgramActions } from '../store/program.action';
import { SessionDetailsCpeComponent } from './session-details-cpe/session-details-cpe.component';
import { ReportService } from 'src/app/services/aws/report/report.service';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { ClassStatusPipe } from 'src/app/widgets/pipes/classstatus.pipe';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
    selector: 'program-sessions',
    templateUrl: './sessions.component.html',
    styleUrls: ['./sessions.component.scss'],
    providers: [ClassStatusPipe]
})
export class SessionsComponent implements OnInit {

    @Input()
    parentSpinner: boolean;

    @Input() sessionCount: number;

    @Output()
    isCancelBannerOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

    mode: string = 'view';
    searchmode: string = 'open';
    public errorMessage: IErrorMessage = {};
    public sessions: ISession[];
    programId: any;
    isQaSelected: boolean;
    isDisplay: boolean = true;
    isDesc: boolean = false;
    column: string = 'title';
    direction: number;
    isTitleAsc: boolean = false;
    isIdAsc: boolean = false;
    isDateAsc: boolean = false;
    isStatusAsc: boolean = false;
    isTimeAsc: boolean = false;
    isLocationAsc: boolean = false;
    showLoading: boolean = true;
    checkboxes: any;
    checkAll: any;
    isSaved: boolean = false;
    isCheckInCodeAsc: boolean = false;
    selectedFilter: string = 'all'; // Default filter
    selectedItems: Set<number> = new Set(); // Tracks selected row IDs
    selectedSingleSetItem: Set<number> = new Set();
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    private openConfirmDialog(body: string): Observable<undefined> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "",
                body: body,
                buttonTextNo: "Cancel",
                buttonTextYes: "Yes, cancel it!"
            },
            panelClass: 'notification-submit-dialog'
        }).afterClosed();
    }

    sessionCancelBannerOpen(isBannerOpen: boolean) {
        this.isCancelBannerOpen.emit(isBannerOpen);
    }

    constructor(private programService: ProgramService, private route: ActivatedRoute, public dialog: MatDialog, private store: Store, private router: Router, private reportService: ReportService,
        private classStatusPipe: ClassStatusPipe, private snackBar: MatSnackBar
    ) {
        this.route.params.pipe(take(1)).subscribe(params => {
            this.programId = params["id"];
        });
    }

    ngOnInit() {
        this.getClasses(this.programId);
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
            if (!this.programService.isSesionsModalOpen && val?.url.split(`programs/${this.programId}`)[1] !== 'Sessions' && val?.navigationTrigger === 'popstate') {
                const sessionId = decodeURIComponent(val?.url.split(`programs/${this.programId}`)[1])?.replace('/', '');
                const _sessionId = this.sessions.find((value: ISession) => value.id == sessionId);
                if (_sessionId) {
                    this.openDialog({
                        id: sessionId
                    });
                }
            }
        });
    }

    public getClasses(id): void {
        this.showLoading = this.parentSpinner;
        this.programService.getSessionsByProgram(id)
            .subscribe((response: ISession[]) => {
                this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this.showLoading = false;
                let sortResponse = this.programService?.programSort(response);
                // this.selectedFilter= "all";
                this.selectedItems.clear();
                this.sessions = sortResponse?.map(session => {
                    if (session.isCpeEligible === false) {
                        session.checkInCode = "";
                    }
                    if (!this.isCompletedOrCancelled(session.startDate, session.endDate, session.isCancelled)) {
                        session.isCompleted = true;
                    }
                    this.sessionCancelBannerOpen(false);
                    return session
                })
            },
                () => {
                    this.showLoading = false;
                    this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                });
    }

    getFilteredItems() {
        if (this.selectedFilter === 'cancelled') {
            return this.sessions.filter(item => item.isCancelled);
        } else if (this.selectedFilter === 'notCancelled') {
            return this.sessions.filter(item => !item.isCancelled);
        }
        return this.sessions; // Return all if no filter is applied
    }

    isInactiveSessionSelected(): boolean {
        if (this.selectedFilter == "cancelled") {
            return true;
        }
        else { return false }

    }

    // Toggle selection for individual rows
    toggleSelection(item: any) {
        if (item.isCancelled) return; // Prevent selecting already cancelled rows

        if (this.selectedItems.has(item.id)) {
            this.selectedItems.delete(item.id);
        } else {
            this.selectedItems.add(item.id);
        }

        if (this.selectedItems.size) {
            this.sessionCancelBannerOpen(true)
        }
        else {
            this.sessionCancelBannerOpen(false)
        }
    }

    // Check if all selectable rows are checked
    areAllSelectableRowsChecked(): boolean {
        const selectableItems = this.getFilteredItems().filter(item => !item.isCancelled && !item.isCompleted);
        return selectableItems.every(item => this.selectedItems.has(Number(item.id)));
    }

    // Toggle "Select All" checkbox
    toggleSelectAll(event: any) {
        const isChecked = event.target.checked;
        const selectableItems = this.getFilteredItems().filter(item => !item.isCancelled && !item.isCompleted);

        if (isChecked) {
            selectableItems.forEach(item => this.selectedItems.add(Number(item.id)));
        } else {
            selectableItems.forEach(item => this.selectedItems.delete(Number(item.id)));
        }

        if (this.selectedItems.size) {
            this.sessionCancelBannerOpen(true)
        }
        else {
            this.sessionCancelBannerOpen(false)
        }

    }

    // Cancel individual row
    cancelItem(item: any) {
        if (!item.isCancelled) {
            this.openConfirmDialog("Are you sure you want to cancel the session?")
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.showLoading = true;
                        // item.isCancelled = true;
                        this.programService.deleteSessions(this.programId, item.id)
                            .subscribe(() => {
                                this.showLoading = false;
                                this.getClasses(this.programId);
                                this.selectedItems.clear();
                                this.snackBar.open('Session Cancelled successfully.', 'OK', {
                                    duration: 5000,
                                    horizontalPosition: this.horizontalPosition,
                                    verticalPosition: this.verticalPosition,
                                    panelClass: ['success-snackbar']
                                });
                            },
                                () => {
                                    this.showLoading = false;
                                    this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                                        duration: 8000,
                                        horizontalPosition: this.horizontalPosition,
                                        verticalPosition: this.verticalPosition,
                                        panelClass: ['error-snackbar']
                                    });
                                    this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                                });
                    }
                },

                    () => {
                        this.showLoading = false;
                        this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                    })
            // this.selectedItems.delete(item.id); // Remove from selected items
        }
    }

    // Cancel all selected items
    cancelSelectedItems() {
        this.openConfirmDialog("Are you sure you want to cancel the selected session(s)?")
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.showLoading = true;
                    this.programService.deleteSessions(this.programId, this.selectedItems)
                        .subscribe(() => {
                            this.showLoading = false;
                            this.getClasses(this.programId);
                            this.selectedItems.clear();
                            this.snackBar.open('Session(s) Cancelled successfully.', 'OK', {
                                duration: 5000,
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                                panelClass: ['success-snackbar']
                            });
                        },
                            () => {
                                this.showLoading = false;
                                this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                                    duration: 8000,
                                    horizontalPosition: this.horizontalPosition,
                                    verticalPosition: this.verticalPosition,
                                    panelClass: ['error-snackbar']
                                });
                                this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                            });
                }
            },

                () => {
                    this.showLoading = false;
                    this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                })
        // this.selectedItems.clear(); // Clear selections after canceling
    }

    isCompletedOrCancelled(sessionStartDate: any, sessionEndDate: any, isCancelled: boolean) {

        if ((this.classStatusPipe.transform(sessionStartDate, sessionEndDate) == "COMPLETE") || isCancelled) {
            return false
        }
        else {
            return true
        };

    }

    isSessionValid(): boolean {
        if (!this.sessions || this.sessions?.length === 0) {
            return false;
        }
        return this.sessions.every((session) => session.isCompleted === true)
    }

    getSessionCount(): number {
        if (!this.sessions || !Array.isArray(this.sessions)) {
            return 0;
        }

        return this.sessions?.filter(session => !session.isCancelled)?.length;
    }


    togglesession() {
        this.mode = this.mode != 'edit' ? 'edit' : 'view';
        this.searchmode = this.searchmode != 'close' ? 'close' : 'open';
    }

    checkUncheckAll() {
        this.errorMessage = {};
        this.isDisplay = false;
    }

    isAllSelected() {
        this.isDisplay = false;
    }

    toggleEventChange($event, eventSetting) {
        this.uncheckAll($event);
        this.programService.updateSession(
            {
                id: eventSetting.id,
                qa: $event.target.checked
            } as ISession
        );
        this.errorMessage = {};
        this.isDisplay = false;
    }

    public openDialog(session: any) {
        let dialogRef: MatDialogRef<SessionDetailsCpeComponent, any> | MatDialogRef<SessionDetailsComponent, any>;
        let dialogData = {
            data: {
                sessionId: session.id,
                programId: this.programId
            },
            panelClass: 'class-roster-dialog'
        }
        if (session.isCpeEligible === true) {
            dialogRef = this.dialog.open(SessionDetailsCpeComponent, dialogData);
        }
        else {
            dialogRef = this.dialog.open(SessionDetailsComponent, dialogData);
        }
        this.programService.isSesionsModalOpen = true;
        this.router.navigate([`/admin/programs/${this.programId}/sessions`]);
        dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
            setTimeout(() => {
                this.router.navigate([`/admin/programs/${this.programId}/sessions`]);
                this.programService.isSesionsModalOpen = false;
            }, 30);
        });
    }

    private uncheckAll($event: any) {
        if ($event.srcElement.checked == false) {
            this.checkAll = document.getElementsByName('qa');
            this.checkAll[0].checked = false;
        }
    }
    valueChangeForAll(sessionId, value) {
        this.programService.updateSession(
            {
                id: sessionId,
                qa: value
            } as ISession
        );
    }

    save() {

        if (this.programService.isDirty('session')) {
            this.programService.save(this.programId, 'session').pipe(take(1)).subscribe(
                () => {
                    this.popOverMessage();
                });
        }
        this.programService.cancelUpdate('session');
    }

    sort(property, name, event) {
        event.stopPropagation();
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'title':
                this.isTitleAsc = !this.isTitleAsc;
                break;
            case 'classId':
                this.isIdAsc = !this.isIdAsc;
                break;
            case 'checkInCode':
                this.isCheckInCodeAsc = !this.isCheckInCodeAsc;
                break;
            case 'startDate':
                {
                    if (name == 'status') this.isStatusAsc = !this.isStatusAsc;
                    else if (name == 'starttime') this.isTimeAsc = !this.isTimeAsc;
                    else this.isDateAsc = !this.isDateAsc;
                    break;
                }
            case 'location':
                this.isLocationAsc = !this.isLocationAsc;
        }
    }

    cancel() {
        this.programService.cancelUpdate('session');
        this.getClasses(this.programId);
        this.isDisplay = true;
        this.isQaSelected = false;


    }

    private popOverMessage() {
        this.isDisplay = true;
        this.isSaved = true;
        this.togglesession();
        setTimeout(() => {
            this.isSaved = false,
                this.togglesession();
        }, 2000);
    }

    public downloadReport(): void {
        this.reportService.getEventReport(this.programId, "session", 'SessionsReport');
    }

}
