<div breadcrumbs [option]='breadcrumbOptions'>
</div>

<h1 prog-head *ngIf="'{{!(getProgramName$ | async)}}';refreshBlock">{{(program$ | async)?.name}}</h1>
<ng-template #refreshBlock>{{getProgramName$ | async}}</ng-template>
<div [@.disabled]="true">
    <mat-tab-group [(selectedIndex)]="setTabActive" (selectedTabChange)="navigateToTab($event)">
        <mat-tab label="Program Overview" *ngIf="isAdminRole">
            <program-overview (sessionCount)=sessionCountReceived($event)
                [parentSpinner]="showGlobalSpinnerParent"></program-overview>
        </mat-tab>
        <mat-tab label="Classes" *ngIf="isAdminRole">
            <program-classes [parentSpinner]="showGlobalSpinnerParent"></program-classes>
        </mat-tab>
        <mat-tab label="Sessions" *ngIf="isAdminRole">
            <program-sessions (isCancelBannerOpen)="getCancelBannerStatus($event)"
                [parentSpinner]="showGlobalSpinnerParent" [sessionCount]="sessionCount"></program-sessions>
        </mat-tab>
        <mat-tab label="Registration Status" *ngIf="isAdminRole">
            <program-registration [parentSpinner]="showGlobalSpinnerParent"></program-registration>
        </mat-tab>
        <mat-tab label="Program Documents" *ngIf="isAdminRole">
            <app-program-documents (isProgramDocOpen)="getProgramDocFormStatus($event)"
                [parentSpinner]="showGlobalSpinnerParent"></app-program-documents>
        </mat-tab>
        <mat-tab label="Classes" *ngIf="!isAdminRole">
            <program-classes></program-classes>
        </mat-tab>
    </mat-tab-group>
</div>